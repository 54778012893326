import capitalize from "./capitalize"

/**
 * Get the site name from an url
 */
function urlToSiteName(url) {
  let match = url.match(/^https?:\/\/([a-z-.]+)/)

  if (!match) return url

  let siteName = match[1] // gets host name like xxx.xxx.xxx.xxx
    .split(".") // get host name parts
    .slice(0, -1) // ignore last item (the tld extension, like .com)
    .filter(part => part.length > 3) // filter out .co, etc.
    .pop() // last element in array is the tld string
    .split("-") // get words
    .map(capitalize) // title case
    .join(" ") // convert array to string

  // edge cases
  switch (siteName) {
    case "Linkedin":
      return "LinkedIn"
  }

  return siteName
}

export default urlToSiteName
