// config
import { EVENTS } from "../config"

// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

/**
 * Custom cursor behaviour
 */
const Cursor = withProps(
  Listener,
  ({ init }) => ({
    init,
    throttle: {
      delay: 0,
    },
    elements: <div id="cursor"></div>,
  }),
  {
    init: on => {
      let cursorNode
      let translate = ""

      const getCursorElement = e => {
        cursorNode = document.querySelector("#cursor")
        follow()
      }

      const show = e => {
        if (cursorNode) {
          cursorNode.style.display = "block"
        }
      }

      const hide = e => {
        if (cursorNode) {
          cursorNode.style.display = "none"
        }
      }

      const track = e => {
        translate = `translate3d(${e.clientX}px, ${e.clientY}px, 0px)`
      }

      const follow = () => {
        if (cursorNode) {
          cursorNode.style.transform = translate
        }

        requestAnimationFrame(follow)
      }

      const detect = () => {
        detectClickableElements()
        detectDraggableElements()
      }

      const setClass = name => {
        if (cursorNode) {
          name
            ? cursorNode.classList.add(name)
            : cursorNode.classList.remove("clickable", "draggable")
        }
      }

      const detectClickableElements = () => {
        const clickable = Array.from(
          document.querySelectorAll(
            ".anchor, .button, a, button, input, textarea, select, *[onClick]"
          )
        )

        clickable.forEach(node => {
          // node.removeEventListener("mouseenter", hoverClickable)
          // node.removeEventListener("mouseleave", reset)
          node.addEventListener("mouseenter", hoverClickable)
          node.addEventListener("mouseleave", reset)
        })
      }

      const detectDraggableElements = () => {
        const draggable = Array.from(
          document.querySelectorAll("*[draggable], .flickity-viewport")
        )

        draggable.forEach(node => {
          // node.removeEventListener("mouseover", hoverDraggable)
          // node.removeEventListener("mouseleave", reset)
          node.addEventListener("mouseover", hoverDraggable)
          node.addEventListener("mouseleave", reset)
        })
      }

      const hoverDraggable = e => {
        setClass("draggable")
      }

      const hoverClickable = e => {
        if (e.target.disabled) return reset(e)

        setClass("clickable")
      }

      const reset = e => {
        setClass(false)
      }

      on(EVENTS.INITIAL_CLIENT_RENDER, follow)
      on(EVENTS.ROUTE_UPDATE, getCursorElement)
      on(EVENTS.FORCE_REDETECT, detect)
      on("mousemove", track)
      on("scroll", reset)

      on("mousemove", show)
      on("touchstart", hide)
    },
  }
)

/**
 * Exports
 */
export default Cursor
