// config
import { EVENTS } from "../config"

// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

/**
 * Align sectors to grid
 */
const AlignToColumns = withProps(
  Listener,
  ({ init }) => ({
    init,
    elements: (
      <div id="full_content_measurer">
        <div></div>
      </div>
    ),
  }),
  {
    init: on => {
      const align = () => {
        const columnNodes = Array.from(
          document.querySelectorAll(".align_to_columns")
        )
        const contentNode = document.querySelector("#full_content_measurer div")

        if (!columnNodes.length || !contentNode) return

        const contentWidth = contentNode.getBoundingClientRect().width
        const columnWidth = contentWidth / 4 - 32.5

        columnNodes.forEach(node => {
          node.style.width = `${columnWidth}px`
        })
      }

      on(EVENTS.FORCE_REDETECT, align)
      on("resize", align)
    },
  }
)

/**
 * Exports
 */
export default AlignToColumns
