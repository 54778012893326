// components
import List from "../../core/components/content/list"

// hocs
import withData from "../../core/hocs/data"
import withProps from "../../core/hocs/props"

// queries
import socialNetworksJSON from "../data/social-networks"
import useImagesInline from "../queries/images-inline"

// utilities
import by from "../../core/utility/by"

/**
 * Show list of social network links with inline svg icons.
 */
const SocialNetworks = withData(
  withProps(List, {
    listClassName: "social_networks",
    itemClassName: "social_network",
  }),
  ({ socialNetworksJSON: list, imagesInline: images }) => ({
    list,
    images: images.filter(by("relativePath", /social-networks/)),
  }),
  { socialNetworksJSON },
  useImagesInline
)

/**
 * Exports
 */
export default SocialNetworks
