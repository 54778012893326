/**
 * Imports
 */
import withGSAPBulkAnimation from "../animations/gsap-bulk"
import withTriggerEvent from "../events/trigger"

/**
 * IntroGSAPFactory
 */
function IntroGSAPFactory(
  Component,
  tolerance,
  gsapOpts,
  trigger = "intersect"
) {
  gsapOpts = Object.assign({ opacity: 0, ease: "expo.out" }, gsapOpts)

  return withTriggerEvent(withGSAPBulkAnimation(Component, gsapOpts), {
    tolerance: tolerance,
    listeners: {
      [trigger]: function() {
        this.play()
      },
    },
  })
}

/**
 * Exports
 */
export default IntroGSAPFactory
