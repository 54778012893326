/**
 * Index an array of objects by field.
 */
function indexByField(field, array) {
  const itemsByField = {}
  array.forEach(item => (itemsByField[item[field]] = item))

  return itemsByField
}

export default indexByField
