// config
import { EVENTS } from "../config"

// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

// utility
import isMobile from "../../core/utility/is-mobile"

/**
 * Align sectors to grid
 */
const AlignToColumns = withProps(
  Listener,
  ({ init }) => ({
    init,
  }),
  {
    init: on => {
      const align = () => {
        const nodes = Array.from(
          document.querySelectorAll(
            "#banner .heading[data-level='2'], #banner .block:nth-child(2) .paragraph"
          )
        )

        if (nodes.length < 2) return

        const scrollTop = document.pageYOffset || document.body.scrollTop
        const tops = nodes.map(
          node =>
            node.getBoundingClientRect().top +
            scrollTop -
            (parseInt(node.style.top, 10) || 0)
        )

        const [heading, paragraph] = nodes
        const [headingTop, paragraphTop] = tops

        let offset = 0
        if (!isMobile()) {
          offset = paragraphTop - headingTop
        }

        heading.style.position = `relative`
        heading.style.top = `${offset}px`
      }

      on(EVENTS.FORCE_REDETECT, align)
      on("resize", align)
    },
  }
)

/**
 * Exports
 */
export default AlignToColumns
