/**
 * useImagesFluidWithWebpTracedSVG
 *
 * Query image sharp fluid images.
 */

/**
 * Imports
 */

// gatsby
import { useStaticQuery, graphql } from "gatsby"

/**
 * Exports
 */
function useImagesFluid360() {
  const data = useStaticQuery(graphql`
    query ImagesFluid360 {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
            parent {
              ... on File {
                relativePath
              }
            }
          }
        }
      }
    }
  `)

  return {
    imagesFluid360: data.allImageSharp.edges.map(({ node }) =>
      Object.assign({}, node.fluid, node.parent)
    ),
  }
}

/**
 * Exports
 */
export default useImagesFluid360
