// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// <Odometer />
type Props = {
  /** Automatically play animation */
  autoPlay: boolean,
  /** Animation duration (seconds) */
  duration: number,
  /** Start value for odometer */
  startValue: number,
  /** A formatter function name, or custom function */
  formatter:
    | "wholeNumber"
    | "padStart"
    | "i18n"
    | ((value: number, opts: Object) => string | number),
  /** Opts to pass to formatter function */
  opts: Object,
  /** Opts to pass to gsap timeline/tween */
  gsapOpts: Object,
  /** Root element class name */
  className?: string,
  /** Content */
  children: React.Node,
  ...
}

type State = {
  /** Current value of odometer  */
  currentValue: number,
}

/**
 * Animate a number between two values with an odometer-like effect.
 *
 * Use formatter functions to process the animated value as needed. The built-in
 * formats are:
 *
 * - wholeNumber: round value to the nearest whole number (default)
 * - padStart: pad the start of a number with a character, eg. 001
 * - i18n: international number formats, eg. 1,000
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .odometer        | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/odometer.scss
 */
class Odometer extends React.Component<Props, State> {
  static defaultProps = {
    autoPlay: false,
    duration: 1.5,
    startValue: 0,
    formatter: "wholeNumber",
    opts: {},
    gsapOpts: {},
  }

  static formatters = {
    /**
     * Round to the nearest whole number
     */
    wholeNumber: (value: number, opts: Object | void) => ~~value,

    /**
     * Pad start of number with a character. eg. 1 -> 001
     */
    padStart: (value: number, opts: { length: number, character: string }) => {
      return `${~~value}`.padStart(opts.length || 3, opts.character || "0")
    },

    /**
     * Internationalize a number. eg. 1000 -> 1,000 or 1.000
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
     */
    i18n: (value: number, opts: Object) => {
      const { locales, ...formatOpts } = opts
      return new Intl.NumberFormat(locales, opts).format(~~value)
    },
  }

  state = {
    currentValue: 0,
  }

  // component props
  timeline: Object

  // custom methods

  /** Play odometer animation */
  play() {
    if (this.timeline) {
      this.timeline.play()
    }
  }

  /** Pause odometer animation */
  pause() {
    if (this.timeline) {
      this.timeline.pause()
    }
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).play = this.play.bind(this)
    ;(this: any).pause = this.pause.bind(this)
  }

  componentDidMount() {
    let {
      // value opts
      startValue,
      children: targetValue,
      // animation props
      autoPlay,
      duration,
      // tween opts
      gsapOpts,
    } = this.props

    // use an object for easy integration with gsap & react state
    const valueObject = { currentValue: startValue }
    this.setState(valueObject)

    // ensure values are whole numbers
    startValue = parseInt(startValue, 10)
    targetValue = parseInt(targetValue, 10)

    // create animation
    this.timeline = gsap
      .timeline({
        paused: !autoPlay,
        onUpdate: () => this.setState(valueObject),
      })
      .fromTo(
        valueObject,
        { currentValue: startValue },
        Object.assign(
          {},
          {
            currentValue: targetValue,
            duration,
            ease: "power1.in",
          },
          gsapOpts
        )
      )
  }

  componentWillUnmount() {
    if (this.timeline) {
      this.timeline.clear().kill()
    }
  }

  render() {
    let {
      // element props
      duration,
      startValue,
      formatter,
      opts,
      gsapOpts,
      // class names
      className,
      // content
      children,
      // passthru
      ...odometerProps
    } = this.props

    // get current value of odometer
    let { currentValue } = this.state

    // get built in formatter functions
    if (typeof formatter === "string") {
      formatter = Odometer.formatters[formatter]
    }

    // apply formatter function if one exists
    if (typeof formatter === "function") {
      currentValue = formatter(currentValue, opts)
    }

    return (
      <span {...classNames("odometer").plus(className)} {...odometerProps}>
        {currentValue}
      </span>
    )
  }
}

/**
 * Exports
 */
export default Odometer
