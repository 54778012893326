/**
 * Parse input as boolean, where string "true" and "false" are the same as
 * true and false.
 */
function parseBoolean(input, opts) {
  // allow per-function call config
  let parser = Object.assign(
    { truthy: ["true", "yes", "on"], falsy: ["false", "no", "off"] },
    opts
  )

  // parse truthy
  if (parser.truthy && parser.truthy instanceof Array) {
    for (let truthy of parser.truthy) {
      if (input === truthy) return true
    }
  }

  // parse falsy
  if (parser.falsy && parser.falsy instanceof Array) {
    for (let falsy of parser.falsy) {
      if (input === falsy) return false
    }
  }

  // parse others
  return !!input
}

export default parseBoolean
