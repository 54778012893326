/**
 * Test if element is visible
 * @see https://stackoverflow.com/a/33456469/10697429
 */
function isVisibleElement(elem) {
  // check if hidden by size
  const hiddenBySize = !(
    elem.offsetWidth ||
    elem.offsetHeight ||
    elem.getClientRects().length
  )

  if (hiddenBySize) return false

  // check if hidden by style
  var style = window.getComputedStyle(elem)
  const hiddenByStyle =
    style.width === "0" ||
    style.height === "0" ||
    style.opacity === "0" ||
    style.display === "none" ||
    style.visibility === "hidden"

  if (hiddenByStyle) return false

  // @todo... other checks like is off screen, hidden by overflow, etc.

  return true
}

export default isVisibleElement
