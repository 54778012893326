// libraries
import axios from "axios"

// components
import { default as ContactComponent } from "../../core/components/forms/contact"

// hocs
import withHandler from "../../core/hocs/handler"

const MAIL_HANDLER = "https://hausman.com/mail/send.php"

// handlers
const sendDataToEmailAddress = async function(data) {
  console.log("handle with", data)

  const formData = new FormData()
  Object.keys(data).forEach(key => {
    const value = data[key]

    formData.append(key, value)
  })

  // get response
  let response
  try {
    console.log("POST", MAIL_HANDLER, formData)
    response = await axios.post(MAIL_HANDLER, formData)

    console.log(response)
    return response.data
  } catch (err) {
    if (err.isAxiosError) {
      console.log(err.toJSON())
      let { message } = err.toJSON()

      if (message.match(/status code 400/))
        return {
          result: "error",
          msg: "Missing required fields.",
        }

      if (message.match(/status code 429/))
        return {
          result: "error",
          msg: "Too many emails, try again tomorrow.",
        }

      if (message.match(/status code 401/))
        return {
          result: "error",
          msg: "This IP Address is blacklisted.",
        }

      if (message.match(/status code 500/))
        return {
          result: "error",
          msg: "Server error.",
        }

      return {
        result: "error",
        msg: err.toJSON().message,
      }
    }

    return {
      result: "error",
      msg: "Check internet connection",
    }
  }
}

/**
 * Create contact with mailchimp service.
 */
const Contact = withHandler(ContactComponent, null, sendDataToEmailAddress)

/**
 * Exports
 */
export default Contact
