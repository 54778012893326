/**
 * Imports
 */
import React from "react"
import classNames from "react-css-module-classnames"
import lottie from "lottie-web"

/**
 * Default Values
 */
const defaultOpts = {
  // render mode
  renderer: "svg",
  // loop
  loop: false,
  // autoPlay
  autoPlay: false,
  // delay
  delay: 0,
  // animation json data
  data: "",
  // segments
  defaultSegment: "all",
  segments: {
    all: ["first-frame", "last-frame"],
  },
}

let nameTicker = 0

/**
 * withLottieAnimation()
 * ==================
 *
 * Replaces a component with a lottie animation, if animationData is provided.
 *
 * // returns original component with props
 * <CompWithLottieAnimation src={imageSrc} />
 *
 * // returns animated svg inside <div data-purpose="lottie-wrapper"></div>
 * // other props are ignored
 * <CompWithLottieAnimation src={imageSrc} lottieOpts={{data: animJson}} />
 *
 * // Opts can be set in the HOC or on the component
 * const CompWithLottieAnimation = withLottieAnimation(Comp, {data: animJson})
 * <CompWithLottieAnimation lottieOpts={{data: animJson}} />
 */
function withLottieAnimation(WrappedComponent = null, funcOpts = {}) {
  /**
   * HOC
   */
  return class extends React.Component {
    /**
     * constructor
     */
    constructor(props) {
      super(props)

      // object props
      this.lottieAnimation = { isLoaded: false }
      this.segment = null

      const { defaultSegment } = this.getOpts()

      // default state
      this.state = {
        segment: defaultSegment,
        frames: {
          first: null,
          center: null,
          last: null,
        },
      }

      // get element ref
      this.self = React.createRef()

      // track timeouts
      this.timeouts = []

      // bind funcs
      this.play = this.play.bind(this)
    }

    /**
     * getOpts
     */
    getOpts() {
      return Object.assign({}, defaultOpts, funcOpts, this.props.lottieOpts)
    }
    /**
     * componentDidMount
     */
    componentDidMount() {
      // get opts
      const {
        data: animationData,
        frames: framesFromOpts,
        quality: qualityFromOpts,
        ...opts
      } = this.getOpts()

      if (animationData) {
        // handle delay
        if (opts.delay) {
          opts.autoPlay = false
          this.play(true)
        }

        // translate autoPlay to autoplay
        let lottieOpts = Object.assign({ autoplay: opts.autoPlay }, opts)

        this.lottieAnimation = lottie.loadAnimation(
          Object.assign(
            {
              container: this.self.current,
              name: `animation-${nameTicker++}`,
              animationData,
            },
            lottieOpts
          )
        )

        // calculate frames
        const numFrames = this.lottieAnimation.getDuration(true)
        const first = 0
        const last = numFrames
        const center = Math.round((last - first) / 2)
        const frames = Object.assign(
          {},
          this.state.frames,
          {
            first,
            last,
            center,
          },
          framesFromOpts
        )

        this.setState({ frames })
      }
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate(prevProps, prevState) {
      const { segment: prevSegment, frames: prevFrames } = prevState
      const { segment, frames } = this.state

      if (segment !== prevSegment || frames !== prevFrames) {
        this.setSegment(segment)
      }
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
      this.timeouts.forEach(timeout => clearTimeout(timeout))
      this.timeouts = []

      lottie.destroy(this.lottieAnimation.name)
    }

    /**
     * play
     */
    setSegment(segment) {
      if (!segment) segment = this.state.segment

      // get segments from opts
      const { segments } = this.getOpts()
      const { frames } = this.state

      // turn named segment into array
      if (typeof segment === "string") {
        segment = segments[segment]
      }

      // turn named frames into numbers
      segment = (segment || segments.all || []).map(frame => {
        // frames that are numbers are okay
        if (typeof frame === "number") {
          return frame
        }

        // frames that end with -frame ignore the keyword "frame"
        const key = frame.replace(/-frame$/, "")
        const frameFromObj = frames[key]
        if (typeof frameFromObj === "number") {
          return frameFromObj
        }

        // no frame found
        throw new Error(`Could not find frame ${frame}`)
      })

      // handle single frame segment
      if (segment.length === 1) segment.push(segment[0])

      // set segment
      this.segment = segment

      // play
      if (!this.lottieAnimation.isPaused) {
        this.play(true)
      }
    }

    /**
     * play
     */
    play(segment, force = true) {
      const { delay } = this.getOpts()

      if (typeof segment === "boolean") force = segment
      else {
        this.setState({ segment })
      }

      this.timeouts.push(
        setTimeout(() => {
          const { lottieAnimation, segment } = this

          if (!lottieAnimation.isLoaded) return
          if (!lottieAnimation.isPaused && !force) return
          if (!segment) return

          lottieAnimation.playSegments(segment, force)
        }, delay * 1000)
      )
    }

    /**
     * pause
     */
    pause() {
      if (!this.lottieAnimation.isLoaded) return

      console.warn("NOT IMPLEMENTED YET")
    }

    /**
     * render
     */
    render() {
      let { lottieOpts, fallbackProps, className, ...props } = this.props

      if (!fallbackProps) fallbackProps = {}

      // render animated svg inside wrapper
      return (
        <div
          ref={this.self}
          {...props}
          data-purpose="lottie-wrapper"
          {...classNames("animation").plus(className)}
        >
          {!this.lottieAnimation.isLoaded && WrappedComponent && (
            <WrappedComponent {...fallbackProps} />
          )}
        </div>
      )
    }
  }
}

/**
 * Exports
 */
export default withLottieAnimation
