// react
import React from "react"
import Helmet from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import { deviceType } from "react-device-detect"

// styles
import "./global.scss"

// segments
import Header from "./segments/header"
import Footer from "./segments/footer"

// components
import * as Components from "./components"

// hooks
import useDetectScrollbarWidth from "../core/hooks/detect-scrollbar-width"
import useScrollBodyClass from "../core/hooks/scroll-body-class"

/**
 * Template
 */
export default function({ children, pageContext }) {
  // if no page context, treat as fragment
  if (typeof pageContext === "undefined") {
    return <MDXProvider components={Components}>{children}</MDXProvider>
  }

  // get template name
  let { title, description, template, theme } = pageContext.frontmatter
  template = template || "no_template"

  // handle scroll bar width
  useDetectScrollbarWidth()

  // attach body classes based on scroll position
  useScrollBodyClass()

  return (
    <MDXProvider components={Components}>
      <Components.SEO data={{ page: { title, description } }} />
      <Helmet
        bodyAttributes={{
          id: template,
          "data-device-type": deviceType || "desktop",
          class: theme,
        }}
      />
      <div className="site_wrapper">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
      <Components.AnimateBackgrounds />
      <Components.AnimateParallax />
      <Components.AnimateTransition />
      <Components.AlignToColumns />
      <Components.AlignBanner />
      <Components.Cursor />
      <Components.StickToContainer />
    </MDXProvider>
  )
}
