// components
import { default as OdometerComponent } from "../../core/components/content/odometer"

// hocs
import withProps from "../../core/hocs/props"

// events
import withTriggerEvent from "../../core/events/trigger"

/**
 * Odometer which plays on sight.
 */
const Odometer = withProps(
  withTriggerEvent.intro(
    OdometerComponent,
    function() {
      this.play()
    },
    { tolerance: "-20%" }
  ),
  { formatter: "i18n" }
)

/**
 * Exports
 */
export default Odometer
