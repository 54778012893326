// react
import * as React from "react"

// hocs
import withData from "../../core/hocs/data"

// components
import Anchor from "../../core/components/semantic/anchor"
import Block from "../../core/components/grid/block"
import Heading from "../../core/components/semantic/heading"
import Marquee from "../../core/components/content/marquee"

// data
import locationsJSON from "../data/locations"

// utilities
import by from "../../core/utility/by"

// email
const email = locationsJSON.filter(item => item.email)[0].email

/**
 * Show a contact link as a marquee
 */
const BlockContactMarquee = () => (
  <Block>
    <Marquee speed={25}>
      <Heading>
        <Anchor email={email}>Get in touch</Anchor>
      </Heading>
    </Marquee>
  </Block>
)

/**
 * Exports
 */
export default BlockContactMarquee
