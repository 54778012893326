/**
 * Imports
 */
import React from "react"
import ReactDOM from "react-dom"
import gsap from "gsap"

import isEmptyObject from "../utility/is-empty-object"

const defaultOpts = {
  // tween defaults
  duration: 0,
  ease: "expo.out",
  delay: 0,
  // elements to animate
  // either an array of selectors
  // or an object like: {selector: {duration, opacity, ease, delay }}
  // "self" animates "WrappedComponent"
  targets: ["self"],
}
/**
 * withGroupAnimation()
 */
function withGroupAnimation(WrappedComponent, funcOpts = {}) {
  /**
   * HOC
   */
  return class extends React.Component {
    /**
     * constructor
     */
    constructor(props) {
      super(props)

      // ref
      this.self = React.createRef()

      // bind functions
      this.getOpts = this.getOpts.bind(this)
      this.detectAnimatedNodes = this.detectAnimatedNodes.bind(this)
      this.addAnimation = this.addAnimation.bind(this)
      this.removeAllAnimation = this.removeAllAnimation.bind(this)
    }

    getOpts() {
      return Object.assign({}, defaultOpts, funcOpts, this.props.animation)
    }

    /**
     * detectAnimatedNodes
     */
    detectAnimatedNodes() {
      // get element
      let element = ReactDOM.findDOMNode(this.self.current)

      // get opts
      const opts = this.getOpts()
      const { targets, ...defaultTween } = opts

      // get array of selectors
      const selectors =
        targets instanceof Array ? targets : Object.keys(targets)

      // generate timeline animation for each selector
      selectors.forEach(selector => {
        // get target element
        let target
        if (selector === "self") {
          target = element
        } else {
          target = element.querySelectorAll(selector)
        }

        let tween = targets[selector]
        let { from, to } = { from: tween, to: {} }
        if (typeof tween === "object") {
          const { from: propFrom, to: propTo } = tween
          if (propFrom && !isEmptyObject(propFrom)) {
            from = propFrom
          }
          if (propTo && !isEmptyObject(propTo)) {
            to = propTo
          }
        }

        // console.log("tweens", targets, selector, tween, tween, from, to)

        // add animation to target
        this.addAnimation(
          target,
          Object.assign({ from, to }, defaultTween, tween)
        )
      })
    }

    /**
     * addAnimation
     */
    addAnimation(node, tween) {
      // console.log("add animation", node, tween)
      if (node.length === 0) return

      // create timeline if none exists
      if (!this.timeline) {
        const { autoPlay, loop } = this.getOpts()
        this.timeline = gsap
          .timeline({
            paused: !autoPlay,
            repeat: loop === true ? -1 : loop || 0,
          })
          .add("start-of-animation")
      }

      let { from, to, autoPlay, loop, ...tweenSettings } = tween

      if (typeof from !== "object") from = {}
      if (typeof to !== "object") to = {}

      let hasFromTween = !isEmptyObject(from)
      let hasToTween = !isEmptyObject(to)

      //console.log("animate", node, { from, to }, tweenSettings, hasFromTween, hasToTween)

      if (hasFromTween && hasToTween) {
        this.timeline.fromTo(
          node,
          from,
          Object.assign(tweenSettings, to),
          "start-of-animation"
        )
        //console.log("animate custom fromTo", node, from, Object.assign(to, tweenSettings))
      } else if (hasFromTween) {
        //console.log("animate custom from", node, Object.assign(from, tweenSettings))
        this.timeline.from(
          node,
          Object.assign(tweenSettings, from),
          "start-of-animation"
        )
      } else if (hasToTween) {
        //console.log("animate custom to", node, Object.assign(to, tweenSettings))

        this.timeline.to(
          node,
          Object.assign(tweenSettings, to),
          "start-of-animation"
        )
      } else {
        //console.log("animate from", node, tweenSettings)
        this.timeline.from(node, tweenSettings, "start-of-animation")
      }
    }

    /**
     * removeAllAnimation
     */
    removeAllAnimation() {
      const { timeline } = this
      if (!timeline) return

      // reset all children on the timeline
      timeline
        .getChildren()
        // get elements that exist on timeline
        .map(({ target }) => (target instanceof Array ? target[0] : target))
        // ignore empty targets
        .filter(child => child)
        // remove props from element
        .forEach(child => {
          gsap.set(child, { clearProps: "all" })
        })

      // clear the timeline
      timeline.clear().kill()
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
      this.detectAnimatedNodes()
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate(prevProps, prevState) {
      this.removeAllAnimation()
      this.detectAnimatedNodes()
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
      this.removeAllAnimation()
    }

    play() {
      if (!this.timeline) {
        console.warn("Tried to play non-existant timeline", this)
        return
      }

      this.timeline.play()
    }

    pause() {
      if (!this.timeline) {
        console.warn("Tried to play non-existant timeline", this)
        return
      }

      this.timeline.pause()
    }

    /**
     * render
     */
    render() {
      const { animation, ...props } = this.props

      // otherwise, play animation when it appears on screen
      return <WrappedComponent ref={this.self} {...props} />
    }
  }
}

/**
 * Exports
 */
export default withGroupAnimation
