// react
import * as React from "react"

// hocs
import withData from "../../core/hocs/data"
import withProps from "../../core/hocs/props"

// components
import { Anchor, Carousel } from "../components"

// data
import sectorsJSON from "../data/sectors"

// queries
import useImagesDirect from "../queries/images-direct"

// utilities
import by from "../../core/utility/by"

/**
 * Show a carousel of sectors.
 */
const Sectors = withData(
  withProps(Carousel, {
    scrollBar: false,
    cellClassName: "align_to_columns",
  }),
  ({ sectorsJSON: list, imagesDirect: images }) => ({
    list: list.map(item => {
      // super hacky way to append link to every child
      // @todo fix this
      let lastChild = React.Children.toArray(item.content).pop()
      if (lastChild && lastChild.props) {
        lastChild = React.Children.toArray(lastChild.props.children).pop()
        if (
          lastChild &&
          lastChild.props &&
          lastChild.props.children === "Related Projects"
        ) {
          return item
        }
      }

      item.titleLink = { to: `/sector/${item.slug}` }

      item.content = (
        <>
          <div className="description">{item.content}</div>
          <Anchor to={`/sector/${item.slug}`}>Related Projects</Anchor>
        </>
      )

      return item
    }),
    images: images.filter(by("relativePath", /sectors-carousel/)),
  }),
  { sectorsJSON: sectorsJSON.slice(0) },
  useImagesDirect
)

/**
 * Exports
 */
export default Sectors
