// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gatsby
import { default as GatsbyImg } from "gatsby-image"

// <Decoration />
type Props = {
  from?: string | RegExp,
  /** Component data, perhaps provided by withData() */
  data?: {
    /** Image src from data */
    images: Array<{ relativePath: string, fluid: Object, ... }>,
    /** Image src from data */
    src: string,
    /** Image srcSet from data */
    srcSet: string,
  },
  /** Alternative text */
  alt: string,
  /** Image src */
  src?: string,
  /** Image srcSet */
  srcSet?: string,
  /** Custom class for root element */
  className?: string,
}

/**
 * An image that is not a necessary part of the page content.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .decoration      | Root element                                           |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/decoration.scss
 */
class Decoration extends React.Component<Props> {
  // react methods

  render() {
    let {
      from,
      data,
      alt,
      src,
      srcSet,
      className,
      ...decorationProps
    } = this.props

    let ImageTag = "img"
    let imageProps = Object.assign({}, decorationProps)

    if (data) {
      // data is a list of possible images
      if (from && data.images instanceof Array) {
        data = data.images.filter(img =>
          img.relativePath.match(
            from instanceof RegExp ? from : new RegExp(from)
          )
        )[0]
      }

      if (data && data.src) {
        src = data.src
      }
      if (data && data.srcSet) {
        srcSet = data.srcSet
      }

      // data has fluid data
      if (data && data.fluid) {
        ImageTag = GatsbyImg
        imageProps.fluid = data.fluid
      }
    }

    // set aria role if no alt
    if (!alt) {
      imageProps.role = "presentation"
    }

    if (ImageTag === "img") {
      console.log("Decoration <img>", src, srcSet, "from", this.props)
    } else {
      console.log("Decoration <Gatsby.Img>", src, srcSet, "from", imageProps)
    }

    return (
      <div {...classNames("decoration").plus(className)}>
        <ImageTag src={src} srcSet={srcSet} alt={alt} {...imageProps} />
      </div>
    )
  }
}

/**
 * Exports
 */
export default Decoration
