/**
 * useImagesInline()
 */

/**
 * Imports
 */

import React from "react"

// inline svgs
import Behance from "../assets/library/icons/social-networks/behance.icon.svg"
import Dribble from "../assets/library/icons/social-networks/dribbble.icon.svg"
import Facebook from "../assets/library/icons/social-networks/facebook.icon.svg"
import Google from "../assets/library/icons/social-networks/google.icon.svg"
import Instagram from "../assets/library/icons/social-networks/instagram.icon.svg"
import LinkedIn from "../assets/library/icons/social-networks/linkedin.icon.svg"
import PayPal from "../assets/library/icons/social-networks/paypal.icon.svg"
import Pinterest from "../assets/library/icons/social-networks/pinterest.icon.svg"
import Spotify from "../assets/library/icons/social-networks/spotify.icon.svg"
import Twitter from "../assets/library/icons/social-networks/twitter.icon.svg"
import YouTube from "../assets/library/icons/social-networks/youtube.icon.svg"

/**
 *
 */
function useImagesInline() {
  return {
    imagesInline: [
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "behance",
        element: <Behance />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "dribble",
        element: <Dribble />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "facebook",
        element: <Facebook />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "google",
        element: <Google />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "instagram",
        element: <Instagram />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "linkedin",
        element: <LinkedIn />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "paypal",
        element: <PayPal />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "pinterest",
        element: <Pinterest />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "spotify",
        element: <Spotify />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "twitter",
        element: <Twitter />,
      },
      {
        relativePath: "assets/library/icons/social-networks/",
        name: "youtube",
        element: <YouTube />,
      },
    ],
  }
}

/**
 * Exports
 */
export default useImagesInline
