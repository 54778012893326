/**
 * Array.prototype.sort() helper,
 * Creates a function that orders array of objects by prop value.
 */
function orderedBy(key, order) {
  return (a, b) => {
    const aVal = a[key]
    const bVal = b[key]

    // order by given array
    if (order) {
      return order.indexOf(aVal) - order.indexOf(bVal)
    }

    // order numerically
    if (typeof aVal === "number" && typeof bVal === "number") {
      return aVal - bVal
    }

    // order alphabetically
    if (typeof aVal.localeCompare === "function") {
      return aVal.localeCompare(bVal)
    }

    // no order change
    return 0
  }
}

export default orderedBy
