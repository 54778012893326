// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// hocs
import withDataAsync from "../../core/hocs/data-async"

// components
import Decoration from "../../core/components/media/decoration"
import DescriptionList from "../../core/components/semantic/description-list"
import GeneratedBlock from "../../core/components/grid/generated-block"
import Heading from "../../core/components/semantic/heading"
import ForceRefreshOnDeviceChange from "../../client/compositions/force-refresh-on-device-change"

// compositions
import { ProjectAnchor as AnimateIntroProjectAnchor } from "./animate-intro"

// queries
import useDirectus from "../queries/directus"

// fetchers
import fetchDirectusProjects from "../fetchers/directus-projects"

const Project = props => {
  const { featured, flipped, data, ...projectProps } = props

  return (
    <AnimateIntroProjectAnchor
      to={`/project/${data.project.slug}`}
      {...classNames("project")
        .plus(featured ? "featured" : "")
        .plus(flipped ? "flipped" : "")}
      {...projectProps}
    >
      <ForceRefreshOnDeviceChange />
      <div>
        <Heading level={3}>{data.project.title}</Heading>
        <Decoration data={data.project.images[0]} />
        <DescriptionList
          data={{
            items: [
              {
                title: "Category",
                content: data.project.category,
              },
              {
                title: "Role",
                content: data.project.role,
              },
              {
                title: "Location",
                content: data.project.location,
              },
            ],
          }}
          className="details"
        />
      </div>
    </AnimateIntroProjectAnchor>
  )
}

/**
 * Show a list of projects.
 */
const BlockProjects = props => {
  const ComposedComponent = withDataAsync(
    GeneratedBlock,
    fetchDirectusProjects,
    ({ directus: result }) => {
      if (!result) return {}

      let elements = []
      let { projects } = result

      if (!projects || !projects.length) return {}

      // clone projects
      projects = projects.slice(0)

      // filter by sector
      if (props.sector) {
        projects = projects.filter(project =>
          project.sectors.find(({ slug }) => slug === props.sector)
        )
      }

      // show featured project
      if (props.showFeaturedProject === true) {
        let featuredProject = projects.shift()

        elements = elements.concat([
          // featured title
          <Heading level={2} key={-1}>
            Featured Project
          </Heading>,
          // featured project
          <Project
            featured={true}
            data={{ project: featuredProject }}
            key={-2}
          />,
          // result title
          <Heading level={2} key={-3}>
            All Projects
          </Heading>,
        ])
      }

      // show rest of projects
      elements = elements.concat(
        projects.map((project, i) => (
          <Project data={{ project }} key={i} flipped={props.flipped} />
        ))
      )

      return {
        elements,
      }
    },
    useDirectus
  )
  return <ComposedComponent />
}

/**
 * Exports
 */
BlockProjects.Project = Project

export default BlockProjects
