// config
import { EVENTS } from "../config"

// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

// utility
import getTranslateY from "../../core/utility/get-translate-y"
import getCrossBrowserWindowValues from "../../core/utility/get-cross-browser-window-values"

const STICKY_MARGIN = 64

/**
 * Align sectors to grid
 */
const StickToContainer = withProps(
  Listener,
  ({ init, children }) => ({
    init,
    throttle: {
      delay: 0,
    },
    elements: children,
  }),
  {
    init: on => {
      const win = getCrossBrowserWindowValues()

      const stick = () => {
        const stickyNodes = Array.from(
          document.querySelectorAll(".stick_to_container")
        )

        stickyNodes.forEach(stickyNode => {
          const containerElement = stickyNode.parentElement
          if (!containerElement) {
            console.warn(
              "cannot sticky node without parent element",
              stickyNode
            )
            return
          }

          const scrollTop = win.scroll.top
          const stickyRect = stickyNode.getBoundingClientRect()
          const containerRect = containerElement.getBoundingClientRect()

          // @todo - this should take into account padding/borders
          const containerWidth = containerElement.offsetWidth

          const containerTop = scrollTop + containerRect.top

          const minOffset = 0
          const maxOffset = containerRect.height - stickyRect.height

          let offset = scrollTop - containerTop + STICKY_MARGIN

          if (offset < minOffset) {
            stickyNode.style.position = ""
            stickyNode.style.transform = `none`
            stickyNode.style.width = ""
          } else if (offset >= maxOffset) {
            stickyNode.style.position = ""
            stickyNode.style.transform = `translate3d(0px, ${maxOffset}px, 0px)`
            stickyNode.style.width = ""
          } else {
            stickyNode.style.position = "fixed"
            stickyNode.style.transform = `none`
            stickyNode.style.width = `${containerWidth}px`
            stickyNode.style.left = containerRect.left
            stickyNode.style.top = `${STICKY_MARGIN}px`
          }
        })
      }

      on(EVENTS.FORCE_REDETECT, stick)
      on("resize", stick)
      on("scroll", stick)
    },
  }
)

/**
 * Exports
 */
export default StickToContainer
