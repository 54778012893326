/**
 * Locations
 *
 * Futureproofs use of <nav>
 */

/**
 * Imports
 */

// react
import React from "react"
import PropTypes from "prop-types"
import classNames from "react-css-module-classnames"
import GoogleMapReact from "google-map-react"

// Components
import Heading from "../semantic/heading"
import Anchor from "../semantic/anchor"

// utility
import queryData from "../../utility/query-data"
import stringToSlug from "../../utility/string-to-slug"

/**
 * <Locations />
 */
export default function Locations(props) {
  let { data, className, ...passthruProps } = props
  let { details, images } = data

  if (!(details instanceof Array)) {
    details = [details]
  }

  if (!(images instanceof Array)) {
    images = []
  }

  // return Locations
  return (
    <section {...classNames("locations").plus(className)} {...passthruProps}>
      {details.map(
        ({ title, icon, address, telephone, fax, email, center, zoom }, i) => (
          <div key={i} {...classNames("location")}>
            <div
              {...classNames("map")}
              style={{ height: "100%", width: "100%" }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAq_ARUFJ4qPlPvZNgxz9wNyDEHC_FKOtk",
                }}
                defaultCenter={center}
                defaultZoom={zoom}
              ></GoogleMapReact>
            </div>
            <div {...classNames("details")}>
              <header>
                {images &&
                  queryData(data.images)
                    .get("element")
                    .where("name")
                    .is(icon)}
                {title && <Heading>{title}</Heading>}
              </header>
              <dl>
                {address && (
                  <div className="address">
                    <dt>Address</dt>
                    <dd>
                      <Anchor address={address.join(",")}>
                        {address.map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                      </Anchor>
                    </dd>
                  </div>
                )}

                <div className="contacts">
                  <dt>Contacts</dt>
                  {telephone && (
                    <dd className="telephone">
                      <Anchor telephone={telephone}>{telephone}</Anchor>
                    </dd>
                  )}
                  {fax && (
                    <dd className="fax">
                      <Anchor fax={fax}>{fax}</Anchor>
                    </dd>
                  )}
                  {email && (
                    <dd className="email">
                      <Anchor email={email}>{email}</Anchor>
                    </dd>
                  )}
                </div>
              </dl>
            </div>
          </div>
        )
      )}
    </section>
  )
}

Locations.defaultProps = {
  details: [],
}

Locations.propTypes = {
  details: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
}
