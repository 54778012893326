// components
import { default as VideoComponent } from "../../core/components/media/video"

// hocs
import withData from "../../core/hocs/data"

// queries
import useVideosDirect from "../queries/videos-direct"

/**
 * Create a video player with self-hosted source files.
 */
const Video = withData(
  VideoComponent,
  ({ videosDirect: videos }) => ({ videos }),
  useVideosDirect
)

/**
 * Exports
 */
export default Video
