// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// components
import Anchor from "../../core/components/semantic/anchor"

// component factories
import IntroGSAPFactory from "../../core/components-factory/intro-gsap"

const AnchorCTA = IntroGSAPFactory(
  class extends React.Component {
    render() {
      const { to, className, children, ...anchorProps } = this.props

      return (
        <Anchor to={to} {...classNames("anchor_cta").plus(className)}>
          <hr /> <span>{children}</span>
        </Anchor>
      )
    }
  },
  "0%",
  {
    opacity: 1,
    duration: 2,
    delay: 0.25,
    targets: {
      hr: {
        from: { scaleX: 0 },
        to: { scaleX: 1 },
      },
      span: {
        from: { opacity: 0, x: "16px" },
        to: { opacity: 1, x: 0 },
        duration: 1.25,
        delay: 0.5,
      },
    },
  }
)

/**
 * Exports
 */
export default AnchorCTA
