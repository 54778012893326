// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <ForceRefreshOnDeviceChange />
type Props = {}

type State = {
  screenWidth: number,
}

class ForceRefreshOnDeviceChange extends React.Component<Props, State> {
  state = {
    screenWidth: null,
  }

  handler = null

  // react methods

  handleResize() {
    this.setState({ screenWidth: window.innerWidth })
  }

  reload() {
    document.body.style.opacity = 0
    window.location.reload(false)
  }

  componentDidMount() {
    this.handleResize()

    this.handler = this.handleResize.bind(this)
    window.addEventListener("resize", this.handler)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handler)
  }

  componentDidUpdate(prevProps, prevState) {
    const { screenWidth: prevScreenWidth } = prevState
    const { screenWidth } = this.state

    // ignore if it's the first size
    if (prevScreenWidth === null) {
      return false
    }

    const deviceBreakpoint = 872

    // reload the device change
    if (prevScreenWidth > deviceBreakpoint && screenWidth <= deviceBreakpoint) {
      this.reload()
    }

    if (prevScreenWidth <= deviceBreakpoint && screenWidth > deviceBreakpoint) {
      this.reload()
    }
  }

  render() {
    return null
  }
}

/**
 * Exports
 */
export default ForceRefreshOnDeviceChange
