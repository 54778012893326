/**
 *  Imports
 */
import React from "react"

/**
 * withHandler()
 *
 * Connect an external service to a component with an optional formatter.
 *
 * const service = function () {
 *   const component = this;
 *   // make service requests
 *   return result;
 * }
 *
 * const formatter = function (result) {
 *   // make changes to result
 *   return result
 * }
 *
 * const Enhanced = withHandler(class extends React.component {
 *   render () {
 *      const result = this.props.handler ();
 *      // ...
 *   }
 * }, formatter, service);
 */
const withHandler = function(WrappedComponent, formatter = null, handler) {
  return class extends React.Component {
    constructor(props) {
      super(props)

      if (typeof handler !== "function") {
        throw new TypeError("withHandler() must define a handler function")
      }

      const boundHandler = handler.bind(this)

      if (typeof formatter === "function") {
        const boundFormatter = formatter.bind(this)

        handler = function(...args) {
          const result = boundHandler(...args)
          return boundFormatter(result)
        }
      }

      this.handler = handler.bind(this)
    }

    render() {
      return <WrappedComponent {...this.props} handler={this.handler} />
    }
  }
}

/**
 * Exports
 */
export default withHandler
