// utilities
import memoize from "../../core/utility/memoize"
import urlToSiteName from "../../core/utility/url-to-site-name"

/**
 * Normalize queries to directus news collection.
 */
async function fetchDirectusNews({ directus: client }) {
  return {
    news: (await client.getItems("news")).data.map(result => ({
      title: result.headline,
      content: result.description,
      url: result.url,
      siteName: urlToSiteName(result.url),
    })),
  }
}

/**
 * Exports
 */
export default memoize(fetchDirectusNews)
