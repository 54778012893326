// react
import * as React from "react"

// hocs
import withData from "../../core/hocs/data"

// components
import Anchor from "../../core/components/semantic/anchor"
import GeneratedBlock from "../../core/components/grid/generated-block"
import Heading from "../../core/components/semantic/heading"
import Marquee from "../../core/components/content/marquee"

// queries
import sectorsJSON from "../data/sectors"

/**
 * Show a list of news stories as a marquee
 */
const BlockSectorsMarquee = withData(
  GeneratedBlock,
  ({ sectorsJSON: sectors }) => ({
    elements: [
      <Marquee speed={25} key={-1}>
        {sectors.map((sector, i) => (
          <React.Fragment key={i}>
            <Anchor to="/sectors">Our Sectors</Anchor>
            <Anchor to={`/sector/${sector.slug}`}>{sector.title}</Anchor>
          </React.Fragment>
        ))}
      </Marquee>,
    ],
  }),
  { sectorsJSON }
)

/**
 * Exports
 */
export default BlockSectorsMarquee
