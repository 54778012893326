/**
 * useDirectus()
 *
 * Query Directus collections.
 */

/**
 * Imports
 */

// gatsby
import { useStaticQuery, graphql } from "gatsby"

// directus
import DirectusSDK from "@directus/sdk-js"

// persistent client
let client

/**
 * DirectusQuery
 */
function useDirectus() {
  if (client) return client

  const data = useStaticQuery(graphql`
    query DirectusQuery {
      site {
        siteMetadata {
          project
          cmsUrl
        }
      }
    }
  `)

  const { cmsUrl: url, project } = data.site.siteMetadata

  const client = new DirectusSDK({
    url,
    project,
  })

  return { directus: client }
}

/**
 * Exports
 */
export default useDirectus
