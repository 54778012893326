/**
 * Header
 */

/**
 * Imports
 */

// react
import React from "react"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// components
import { SiteLogo, HamburgerMenu } from "../components.js"

// animations
import withScrollMagicAnimation from "../../core/animations/scroll-magic"

// utility
import isMobile from "../../core/utility/is-mobile"

/**
 * Manage ScrollMagic animations
 */
const ScrollMagicLogoVisibility = withScrollMagicAnimation(
  null,
  {},
  (scene, getElements) => {
    const offset = 1 // immediately on scroll
    const selector = ".header .site_logo:not(.symbol)"
    const tweenSettings = {
      duration: 0.5,
      ease: "expo.out",
    }

    // tween when visible
    const visible = Object.assign(
      {
        opacity: 1,
        display: "flex",
      },
      tweenSettings
    )

    // tween when hidden
    const hidden = Object.assign(
      {
        opacity: 0,
        display: "none",
      },
      tweenSettings
    )

    scene({ offset })
      .on("leave", event => gsap.to(getElements(selector), visible))
      .on("enter", event => {
        gsap.to(getElements(selector), isMobile() ? visible : hidden)
      })
  }
)

/**
 * <Header />
 */
const Header = () => {
  return (
    <>
      <ScrollMagicLogoVisibility />
      <header {...classNames("header")}>
        <SiteLogo image="symbol" />
        <SiteLogo image="logomark" />
        <SiteLogo image="logomark-black" />

        <div className="right_space">
          <HamburgerMenu />
        </div>
      </header>
    </>
  )
}

/**
 * Exports
 */
export default Header
