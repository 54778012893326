/**
 * Hamburger
 */

/**
 * Imports
 */

// react
import React from "react"
import classNames from "react-css-module-classnames"

// components
import { Heading, Navigation, Anchor, Decoration } from "../components.js"

// data
import locationsJSON from "../data/locations"

/**
 * <Hamburger />
 */
const Hamburger = () => {
  return (
    <>
      <Navigation className="site">
        <Anchor to="/about">Our Story</Anchor>
        <Anchor to="/services">Services</Anchor>
        <Anchor to="/solutions">Solutions</Anchor>
        <Anchor to="/sectors">Sectors</Anchor>
        <Anchor to="/projects">Projects</Anchor>
      </Navigation>
    </>
  )
}

/**
 * Exports
 */
export default Hamburger
