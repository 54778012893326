/**
 * Get the closest number in an array
 */
function closest(needle, haystack) {
  return haystack.reduce(function(prev, curr) {
    return Math.abs(curr - needle) < Math.abs(prev - needle) ? curr : prev
  })
}

export default closest
