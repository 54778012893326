/**
 * useImagesDirect()
 */

/**
 * Imports
 */

// react
import React from "react"

// gatsby
import { useStaticQuery, graphql } from "gatsby"

/**
 * ImagesDirectQuery
 */
function useImagesDirect(filter) {
  const data = useStaticQuery(graphql`
    query ImagesDirectQuery {
      allFile {
        nodes {
          relativePath
          name
          extension
          publicURL
        }
      }
    }
  `)

  return {
    imagesDirect: data.allFile.nodes
      .filter(({ extension }) => ["svg", "jpg", "png"].includes(extension))
      .filter(typeof filter === "function" ? filter : () => true)
      .map(props =>
        Object.assign({}, props, {
          filename: `${props.name}.${props.extension}`,
          src: props.publicURL,
          srcSet: props.publicURL,
          element: <img src={props.publicURL} alt={props.name} />,
        })
      ),
  }
}

/**
 * Exports
 */
export default useImagesDirect
