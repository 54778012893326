/**
 * Imports
 */
import React from "react"

// utility
import isSSR from "../utility/is-ssr"

/**
 * Default Values
 */
const defaultOpts = {}

/**
 * withScrollMagicAnimation()
 */
function withScrollMagicAnimation(
  WrappedComponent = null,
  funcOpts = {},
  handler = () => null
) {
  /**
   * HOC
   */
  return class extends React.Component {
    /**
     * constructor
     */
    constructor(props) {
      super(props)

      // object props
      this.controller = null

      // handler
      this.handler = handler.bind(this)
      this.destroy = this.destroy.bind(this)
    }

    /**
     * getOpts
     */
    getOpts() {
      return Object.assign(
        {},
        defaultOpts,
        funcOpts,
        this.props.scrollMagicOpts
      )
    }

    /**
     * Destroy scenes & reset controller
     */
    destroy() {
      this.controller.destroy()
      this.controller = null
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
      // disable during ssr
      if (isSSR()) return

      // get lib
      const ScrollMagic = require("scrollmagic")

      // get opts
      const { ...opts } = this.getOpts()

      // ensure clean controller
      if (this.controller) {
        this.destroy()
      }

      this.controller = new ScrollMagic.Controller()

      this.handler.call(
        null,
        (...args) => new ScrollMagic.Scene(...args).addTo(this.controller),
        selector => Array.from(document.querySelectorAll(selector))
      )
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
      this.destroy()
    }

    /**
     * render
     */
    render() {
      // nothing gets rendered
      return null
    }
  }
}

/**
 * Exports
 */
export default withScrollMagicAnimation
