// components
import { default as DecorationComponent } from "../../core/components/media/decoration"

// hocs
import withData from "../../core/hocs/data"

// queries
import useImagesDirect from "../../client/queries/images-direct"
import useImagesFluid360 from "../../client/queries/images-fluid-360"
import useImagesFluid1024 from "../../client/queries/images-fluid-1024"
import useImagesFluid1920 from "../../client/queries/images-fluid-1920"

// utility
import getCrossBrowserWindowValues from "../../core/utility/get-cross-browser-window-values"

/**
 * Decoraction with access to gatsby image queries
 */
const Decoration = withData(
  DecorationComponent,
  ({ imagesDirect, imagesFluid360, imagesFluid1024, imagesFluid1920 }) => {
    // combine fluid images into single array
    const fluidImagesBySize = {
      360: imagesFluid360,
      1024: imagesFluid1024,
      1920: imagesFluid1920,
    }

    // pick smallest dataset that fits the screen
    const win = getCrossBrowserWindowValues()

    const size = Math.min.apply(
      null,
      Object.keys(fluidImagesBySize).filter(size => size >= win.size.maxWidth)
    )
    let fluidImages = fluidImagesBySize[size]

    if (typeof fluidImages === "undefined") {
      fluidImages = Object.values(fluidImagesBySize)[0] || []

      console.warn(
        "Couldn't find fluid images for size",
        size,
        "falling back to ",
        fluidImages
      )
    }

    return {
      images: imagesDirect
        .map(img => Object.assign({}, img))
        .map(img => {
          // don't bother finding fluid images for svgs
          if (img.filename.includes(".svg")) return img

          img.fluid = fluidImages.filter(
            fluidImg => fluidImg.relativePath === img.relativePath
          )[0]

          if (typeof img.fluid === "undefined") {
            delete img.fluid
            console.warn("couldn't find fluid image for", img)
          }

          return img
        }),
    }
  },
  useImagesDirect,
  useImagesFluid360,
  useImagesFluid1024,
  useImagesFluid1920
)

/**
 * Exports
 */
export default Decoration
