/**
 *  Imports
 */
import React from "react"

/**
 * withParentComponent()
 *
 * Connect components such that they are callable from a parent component.
 *
 *
 */
const withParentComponent = function(
  WrappedComponent,
  formatter,
  ...Components
) {
  return class extends React.Component {
    constructor(props) {
      super(props)

      this.childRefs = {}
    }

    getChildComponents() {
      return Object.values(this.childRefs).map(
        ({ current: instance }) => instance
      )
    }

    render() {
      let { childProps, ...props } = this.props

      if (!childProps) {
        childProps = []
      }
      if (typeof formatter === "function") {
        childProps = formatter(childProps)
      }

      return (
        <WrappedComponent {...props}>
          {Components.map((Component, i) => {
            const props = childProps[i] || {}

            // allow strings
            if (typeof Component === "string") {
              const str = Component
              Component = class extends React.Component {
                render() {
                  return <span {...this.props}>{this.props.children}</span>
                }
              }
              props.children = [str]
            }

            // setup refs
            props.ref = React.createRef()
            this.childRefs[i] = props.ref

            // create comp
            return <Component key={i} {...props} />
          })}
        </WrappedComponent>
      )
    }
  }
}

/**
 * Exports
 */
export default withParentComponent
