// config
import { ANIMATE_HAMBURGER } from "../config"

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// animation
import withLottieAnimation from "../../core/animations/lottie"

// events
import withTriggerEvent from "../../core/events/trigger"

// hocs
import withPairedComponent from "../../core/hocs/paired-component"

// components
import Modal from "../../core/components/content/modal"
import Button from "../../core/components/semantic/button"

// assets
import hamburgerButton from "../../client/assets/animations/hamburger-button.json"

// segments
import HamburgerContent from "../segments/hamburger.js"

/**
 * Generate hamburger menu.
 */
const HamburgerMenu = withPairedComponent(
  Modal,
  withLottieAnimation(Button, {
    autoPlay: false,
    loop: false,
    defaultSegment: "in",
    segments: {
      in: ["first-frame", "center-frame"],
      out: ["center-frame", "last-frame"],
    },
    data: hamburgerButton,
  }),
  ({ modalProps: firstProps, buttonProps: secondProps }) => ({
    firstProps,
    secondProps,
  }),
  {
    className: "hamburger_menu",
    onChange: function(e) {
      const [modal, button] = this.getPairedComponents()

      button.play(modal.state.active ? "in" : "out")
    },
    onClick: function(e) {
      const [modal] = this.getPairedComponents()

      // close if internal link is clicked
      if (e.target.classList.contains("internal")) {
        modal.close()
      }
    },
    timelines: {
      initialState: () => false,
      in: ({ self, backdrop, content, opts }) => {
        console.log("SHOW HAMBURGER", opts)

        document.body.classList.add("hamburger_visible")

        const { DURATION } = ANIMATE_HAMBURGER

        const navLinks = content.querySelectorAll(".site.navigation li")

        const timeline = gsap
          .timeline(opts)
          .set(self, { display: "block", height: "0%" })
          .set(content, { opacity: 1 })
          .add("animation-start")
          .to(
            self,
            {
              duration: DURATION / 2,
              height: "100%",
              ease: "expo.out",
            },
            "animation-start"
          )
          .to(content, { delay: DURATION }, "animation-start")
          .fromTo(
            navLinks,
            { opacity: 0, y: "32px" },
            {
              y: 0,
              opacity: 1,
              duration: DURATION,
              stagger: DURATION / 20,
              delay: DURATION / 8,
              ease: "expo.out",
            },
            "animation-start"
          )

        return () => timeline.clear().kill()
      },
      out: ({ self, backdrop, content, opts }) => {
        const { DURATION } = ANIMATE_HAMBURGER

        const { onComplete, ...timelineOpts } = opts

        document.body.classList.remove("hamburger_visible")

        const timeline = gsap
          .timeline(
            Object.assign(timelineOpts, {
              onComplete: (...args) => {
                self.style.display = "none"
                if (typeof onComplete === "function") {
                  onComplete(...args)
                }
              },
            })
          )
          .add("animation-start")
          .to(content, {
            duration: 0,
            opacity: 0,
            ease: "expo.out",
          })
          .to(self, {
            duration: DURATION / 2,
            height: "0%",
            ease: "expo.out",
          })

        return () => timeline.clear().kill()
      },
    },
    scrollLock: {
      lock: "end",
      unlock: "start",
    },
    closeButton: false,
    backdrop: true,
    children: <HamburgerContent />,
  },
  {
    className: "hamburger_menu button",
    onClick: function(e) {
      const [modal] = this.getPairedComponents()

      modal.toggle()
    },
  }
)

/**
 * Exports
 */
export default HamburgerMenu
