// react
import * as React from "react"

// hocs
import withDataAsync from "../../core/hocs/data-async"

// components
import {
  Accordian,
  Anchor,
  Heading,
  GeneratedBlock,
  AnimateIntroWrapper,
} from "../components"

// queries
import useDirectus from "../queries/directus"

// fetchers
import fetchDirectusJobs from "../fetchers/directus-jobs"

/**
 * Show a list of job positions in an accordian.
 */
const BlockJobs = withDataAsync(
  GeneratedBlock,
  fetchDirectusJobs,
  ({ directus: result }) => ({
    elements:
      result &&
      result.jobs.map((job, i) => {
        return (
          <AnimateIntroWrapper className="job" key={i}>
            <Accordian
              label={
                <>
                  <Heading level={3} className="no_animation">
                    {job.title}
                  </Heading>
                  <Heading level={4} className="no_animation">
                    <span className="headline">{job.shortContent}</span>
                    <span className="locations">{job.locations}</span>
                  </Heading>
                </>
              }
              className="intro_from_left"
            >
              <div dangerouslySetInnerHTML={{ __html: job.content }} />
              <Anchor url={job.url}>Apply</Anchor>
            </Accordian>
          </AnimateIntroWrapper>
        )
      }),
  }),
  useDirectus
)

/**
 * Exports
 */
export default BlockJobs
