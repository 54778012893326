// config
import { EVENTS } from "../../client/config"

// react
import React from "react"
import ReactDOM from "react-dom"

// intersection observer
import Observer from "@researchgate/react-intersection-observer"

// utilities
import isVisibleElement from "../utility/is-visible-element"

const defaultOpts = {
  once: false,
  tolerance: "-50%",
}
/**
 * withTriggerEvent()
 */
function withTriggerEvent(WrappedComponent, funcOpts = {}) {
  /**
   * HOC
   */
  return class extends React.Component {
    /**
     * constructor
     */
    constructor(props) {
      super(props)

      // track handlers
      this.handlers = []

      // track listener calls
      this.hasBeenCalled = {}

      // ref
      this.self = React.createRef()
    }

    getOpts() {
      return Object.assign({}, defaultOpts, funcOpts, this.props)
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
      const { listeners, once } = this.getOpts()

      const windowEvents = Object.values(EVENTS)
      const component = this.self.current
      const node = ReactDOM.findDOMNode(component)

      Object.keys(listeners).forEach(eventName => {
        const config = listeners[eventName]
        const func = config.bind(component)

        const handler = e => {
          if (once && this.hasBeenCalled[eventName]) return

          func(e)
          this.hasBeenCalled[eventName] = true
        }

        switch (eventName) {
          case "intersect":
            console.log("set intersect handler", eventName)
            this.intersectHandler = handler
            break

          default:
            let target = windowEvents.includes(eventName) ? window : node
            console.log("listen to", target, eventName)

            this.handlers.push({ node: target, eventName, handler })
            target.addEventListener(eventName, handler)
            break
        }
      })
    }

    componentDidUpdate() {
      // reset listeners on update
      this.componentWillUnmount()
      this.componentDidMount()
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
      this.handlers.forEach(({ node, eventName, handler }) => {
        node.removeEventListener(eventName, handler)
        this.hasBeenCalled[eventName] = false
      })

      this.handlers = []
    }

    /**
     * handleIntersection
     */
    handleIntersection({ isIntersecting }) {
      if (isIntersecting && this.intersectHandler) {
        // ignore if in an invisible container
        if (
          !this.self ||
          !this.self.current ||
          !isVisibleElement(ReactDOM.findDOMNode(this.self.current).parentNode)
        )
          return

        this.intersectHandler()
      }
    }

    /**
     * render
     */
    render() {
      const { listeners, tolerance, once, ...props } = this.getOpts()

      if (listeners.intersect && tolerance) {
        return (
          <Observer
            onChange={this.handleIntersection.bind(this)}
            rootMargin={`0% 0% ${tolerance}`}
          >
            <WrappedComponent ref={this.self} {...props} />
          </Observer>
        )
      }

      return <WrappedComponent ref={this.self} {...props} />
    }
  }
}

withTriggerEvent.intro = (WrappedComponent, handler, funcOpts = {}) => {
  return withTriggerEvent(
    WrappedComponent,
    Object.assign(
      {
        tolerance: "-50%",
        once: true,
        listeners: {
          intersect: handler,
        },
      },
      funcOpts
    )
  )
}

/**
 * Exports
 */
export default withTriggerEvent
