/**
 * Footer
 */

/**
 * Imports
 */

// react
import React from "react"
import classNames from "react-css-module-classnames"

// hocs
import withProps from "../../core/hocs/props"

// components
import {
  Decoration,
  SiteLogo,
  SocialNetworks,
  Newsletter,
  Navigation,
  Anchor,
  Contact,
  Wrapper,
} from "../components.js"

// component factories
import IntroGSAPFactory from "../../core/components-factory/intro-gsap"

/**
 * <Footer />
 */
const Footer = () => {
  return (
    <FooterWrapper {...classNames("footer")}>
      <SiteLogo image="logomark" />
      <SocialNetworks />
      <Newsletter
        title="To subscribe to our newsletter, please sign up"
        placeholderText="Enter your email"
        submitText="→"
      />

      <div className="site_menu">
        <Navigation className="site_map">
          <Anchor to="/about">Story</Anchor>
          <Anchor to="/services">Services</Anchor>
          <Anchor to="/solutions">Solutions</Anchor>
          <Anchor to="/careers">Careers</Anchor>
          <Anchor to="/locations">Locations</Anchor>
          <Anchor to="/vendors">Vendors</Anchor>
        </Navigation>
        <ul className="partner_logos decoration_wrapper">
          <li>
            <Anchor to="http://www.manenterprise.com">
              <Decoration
                from="/partner-companies/man-enterprise.svg"
                alt="Man Enterprise"
              />
            </Anchor>
          </li>
          <li>
            <Anchor to="http://www.tecmanindustry.com">
              <Decoration from="/partner-companies/tecman.svg" alt="Tecman" />
            </Anchor>
          </li>
          <li>
            <Anchor to="http://www.metrixmep.com">
              <Decoration from="/partner-companies/metrix.svg" alt="Metrix" />
            </Anchor>
          </li>
        </ul>
      </div>

      <Navigation className="legal_menu">
        <Anchor to="/privacy">Privacy</Anchor>
        <Anchor to="/terms">Terms and Conditions</Anchor>
        <Anchor to="/cookies">Cookies</Anchor>
      </Navigation>

      <div {...classNames("copyright")}>
        Copyright © Hausman - all rights reserved
      </div>
    </FooterWrapper>
  )
}

const FooterWrapper = IntroGSAPFactory(
  withProps(Wrapper, { tag: "footer", className: "footer" }),
  "-50%",
  {
    duration: 0.5,
    targets: {
      ".site_logo, .social_networks": {
        from: { opacity: 0 },
      },
      ".newsletter, .site_menu": {
        from: { y: 32 },
        delay: 0.1,
      },
      ".copyright": {
        from: { x: -32 },
        delay: 0.2,
      },
      ".legal_menu": {
        from: { x: 32 },
        delay: 0.3,
      },
    },
  }
)

/**
 * Exports
 */
Footer.FooterWrapper = FooterWrapper

export default Footer
