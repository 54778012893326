// hocs
import withData from "../../core/hocs/data"

// components
import { default as LocationsComponent } from "../../core/components/semantic/locations"

// data
import locationsJSON from "../data/locations"
import useImagesDirect from "../queries/images-direct"

/**
 * Show contact details.
 */
const Locations = withData(
  LocationsComponent,
  ({ locationsJSON: details, imagesDirect: images }) => ({ details, images }),
  useImagesDirect,
  { locationsJSON }
)

/**
 * Exports
 */
export default Locations
