// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// animation
import withLottieAnimation from "../../core/animations/lottie"

// events
import withTriggerEvent from "../../core/events/trigger"

// hocs
import withParentComponent from "../../core/hocs/parent-component"

// components
import Anchor from "../../core/components/semantic/anchor"

// assets
import white from "../../client/assets/animations/learn-more-white.json"
import turq from "../../client/assets/animations/learn-more-turq.json"

/**
 * Lottie "scroll down" svg animation
 */
const LearnMore = props => {
  const { color, className, ...componentProps } = props

  const Component = withTriggerEvent(
    withParentComponent(
      Anchor,
      ({ lottieProps }) => ({ 1: lottieProps }),
      "Learn more",
      withLottieAnimation(null, {
        autoPlay: true,
        loop: true,
        defaultSegment: "loop",
        segments: {
          loop: ["first-frame", "last-frame"],
          static: [0, 1],
        },
        data: { white, turq }[color || "white"],
      })
    ),
    {
      listeners: {
        mouseover: function() {
          const [, animation] = this.getChildComponents()
          const { lottieAnimation } = animation

          lottieAnimation.loop = false
        },
        mouseout: function() {
          const [, animation] = this.getChildComponents()
          const { lottieAnimation } = animation

          lottieAnimation.loop = true
          lottieAnimation.play()
        },
      },
    }
  )

  return (
    <Component
      {...componentProps}
      {...classNames("learn_more").plus(className)}
    />
  )
}

/**
 * Exports
 */
export default LearnMore
