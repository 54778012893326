import memoize from "../../core/utility/memoize"

/**
 * Normalize queries to directus jobs collection.
 */
async function fetchDirectusJobs({ directus: client }) {
  return {
    jobs: (await client.getItems("jobs")).data.map(result => ({
      title: result.job_title,
      shortContent: result.details,
      locations: result.location,
      content: result.description,
      url: result.url || "mailto:info@hausman.com",
    })),
  }
}

/**
 * Exports
 */
export default memoize(fetchDirectusJobs)
