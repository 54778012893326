// react
import * as React from "react"

// utilities
import by from "../../core/utility/by"
import orderedBy from "../../core/utility/ordered-by"
import memoize from "../../core/utility/memoize"

/**
 * Normalize queries to directus images collection.
 */
async function fetchDirectusImages({ directus: client }) {
  const { data: images } = await client.getItems("directus_files", {
    filter: {
      type: {
        eq: "image/jpeg",
      },
    },
  })

  // similar to images-direct.js
  const result = {
    images: images
      // get fields from directus query
      .map(image => ({
        id: image.id,
        filename: image.filename_disk,
        src: image.data.full_url,
        srcSet: [
          {
            url: image.data.full_url,
            width: image.width,
          },
        ]
          .concat(image.data.thumbnails.filter(by("url", /contain$/)))
          .sort(orderedBy("width"))
          .map(thumbnail => `${thumbnail.url} ${thumbnail.width}w`)
          .join(", "),
      }))
      // get element from fields
      .map(image => {
        image.element = (
          <img src={image.src} srcSet={image.srcSet} alt={image.title} />
        )

        return image
      }),
  }

  return result
}

/**
 * Exports
 */
export default memoize(fetchDirectusImages)
