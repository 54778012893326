// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/data"

// components
import Anchor from "../../core/components/semantic/anchor"
import Block from "../../core/components/grid/block"
import Heading from "../../core/components/semantic/heading"
import Marquee from "../../core/components/content/marquee"

/**
 * Show a list of services as a marquee
 */
const BlockServicesMarquee = () => (
  <Block>
    <Marquee speed={25}>
      <Anchor to="/services">Our Services</Anchor>
      <Anchor to={`/service/operations-and-maintenance`}>
        O&M: Operations and Maintenance
      </Anchor>
      <Anchor to="/services">Our Services</Anchor>
      <Anchor to={`/service/environment-health-and-safety`}>
        EHS: Environment, Health, and Safety
      </Anchor>
      <Anchor to="/services">Our Services</Anchor>
      <Anchor to={`/service/information-management`}>
        Information Management
      </Anchor>
      <Anchor to="/services">Our Services</Anchor>
      <Anchor to={`/service/industrial-camp-accommodation`}>
        Industrual Camp Accommodation
      </Anchor>
    </Marquee>
  </Block>
)

/**
 * Exports
 */
export default BlockServicesMarquee
