// react
import * as React from "react"

// hocs
import withData from "../../core/hocs/data"

// components
import Anchor from "../../core/components/semantic/anchor"
import Block from "../../core/components/grid/block"
import Heading from "../../core/components/semantic/heading"
import Marquee from "../../core/components/content/marquee"

/**
 * Show "next project" link as a marquee
 */
const BlockNextProjectMarquee = props => (
  <Block>
    <Marquee speed={25}>
      <Heading>
        <Anchor to={props.to}>Next Project</Anchor>
      </Heading>
      <Anchor to={props.to}>{props.projectTitle}</Anchor>
    </Marquee>
  </Block>
)

/**
 * Exports
 */
export default BlockNextProjectMarquee
