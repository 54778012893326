// config
import {
  EVENTS,
  ANIMATE_INTRO,
  ANIMATE_PAGE_CHANGE,
  ANIMATE_APPEAR,
} from "../config"

// react
import * as React from "react"
import ReactDOM from "react-dom"

// gsap
import gsap from "gsap"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"
import SiteLogo from "./site-logo"
import RowLines from "./row-lines"

// utility
import callWhenIdle from "../../core/utility/call-when-idle"
import parseBoolean from "../../core/utility/parse-boolean"
import triggerEvent from "../../core/utility/trigger-event"
import isMobile from "../../core/utility/is-mobile"

// assets
import MapAnimation from "../assets/images/site-branding/loading.inline.svg"

/** Animate intro transition with gsap */
const intro = () => {
  const { FADE_DURATION, ANIM_DURATION, FADE_STAGGER } = ANIMATE_INTRO
  const { linesNode, logoNode, mapNode } = getNodes()

  // run extended loading animation
  isAnimatingIntro(true)
  linesNode.style.display = "none"

  const timeline = gsap
    .timeline({
      paused: true,
      onStart: () => {
        document.body.style.opacity = 1
      },
      onComplete: removeIntro,
    })
    .set(logoNode, { opacity: 0 })
    .to(logoNode, { opacity: 1, duration: FADE_DURATION, ease: "expo.in" })
    .to([mapNode, logoNode], {
      opacity: 0,
      duration: FADE_DURATION,
      delay: ANIM_DURATION - FADE_DURATION,
      ease: "linear.none",
      stagger: FADE_STAGGER,
    })

  callWhenIdle(() => {
    ReactDOM.render(<MapAnimation />, mapNode)
    timeline.play()
  })
}

/** Skip intro transition */
const removeIntro = () => {
  const { logoNode, mapNode } = getNodes()

  if (logoNode) logoNode.remove()
  if (mapNode) mapNode.remove()

  isAnimatingIntro(false)
  callWhenIdle(AnimateTransition.pageChangeOut)
}

/** Animate in page transition with css */
const pageChangeIn = () => {
  const { DURATION } = ANIMATE_PAGE_CHANGE
  const { transitionNode: node } = getNodes()

  document.body.style.opacity = 1

  node.style.display = "block"
  node.classList.add("in")
  triggerEvent(EVENTS.PAGE_CHANGE_START)

  setTimeout(() => {
    node.classList.remove("in")
  }, DURATION)
}

/** Animate out page transition with css/gsap */
const pageChangeOut = () => {
  const { DURATION, DELAY_OUT } = ANIMATE_PAGE_CHANGE
  const { transitionNode: node, linesNode, bannerNode } = getNodes()

  // don't run animation if we're not ready
  if (isLoadingAsyncData() || isAnimatingIntro()) return

  document.body.style.opacity = 1

  // scroll to top
  window.scrollTo(0, 0)

  // handle page transition out
  setTimeout(() => {
    node.classList.add("out")
    setTimeout(() => {
      node.style.display = "none"
      node.classList.remove("out")
      linesNode.style.display = "" // show lines on future transitions
      triggerEvent(EVENTS.PAGE_CHANGE_END)
    }, DURATION)
  }, DELAY_OUT)
}

/** Easy/hacky node fetching */
const getNodes = () => ({
  transitionNode: document.querySelector(".transition"),
  linesNode: document.querySelector(".transition .lines"),
  mapNode: document.querySelector(".transition .loading"),
  logoNode: document.querySelector(".transition .logomark"),
  bannerNode: document.querySelector("#banner"),
})

/** Easy/hacky load state flag */
const isLoadingAsyncData = state => {
  const node = document.body

  if (typeof state !== "undefined") {
    return (node.dataset.isLoadingAsyncData = state)
  }
  return parseBoolean(node.dataset.isLoadingAsyncData)
}

/** Easy/hacky animation state flag */
const isAnimatingIntro = state => {
  const node = document.body

  if (typeof state !== "undefined") {
    return (node.dataset.isAnimatingIntro = state)
  }
  return parseBoolean(node.dataset.isAnimatingIntro)
}

/** Easy/hacky animation state flag */
const isAnimatingBanner = state => {
  const node = document.querySelector("#banner")

  if (!node) return

  if (typeof state !== "undefined") {
    return (node.dataset.isAnimatingBanner = state)
  }
  return parseBoolean(node.dataset.isAnimatingBanner)
}

/**
 * Fade background in and out
 */
const AnimateTransition = withProps(
  Listener,
  ({ init }) => ({
    init,
    elements: (
      <div className="transition">
        <div className="shades">
          <div className="shade" />
          <div className="shade" />
          <div className="shade" />
          <div className="shade" />
          <div className="shade" />
          <div className="shade" />
        </div>
        <RowLines />

        <div className="site_logo loading"></div>

        <div className="site_logo logomark">
          <SiteLogo to={null} image="logomark" />
        </div>
      </div>
    ),
  }),
  {
    init: on => {
      // animate in before navigating away from page
      on(EVENTS.WILL_NAVIGATE, ({ detail }) => {
        AnimateTransition.pageChangeIn()
      })

      // animate in when the route updates
      // first route update uses intro animation
      on(EVENTS.ROUTE_UPDATE, ({ detail }) => {
        // play/skip intro animation on first render
        if (detail.prevLocation == null) {
          if (ANIMATE_INTRO.ENABLE && !isMobile()) {
            AnimateTransition.intro()
          } else {
            AnimateTransition.removeIntro()
          }
        } else {
          // simply animate out page change on later renders
          AnimateTransition.pageChangeOut()
        }
      })

      // prevent aniamte out when async data is loading
      on(EVENTS.ASYNC_LOAD_START, () => {
        isLoadingAsyncData(true)
      })

      // animate out when async data finishes loading
      on(EVENTS.ASYNC_LOAD_END_ALL, () => {
        isLoadingAsyncData(false)
        AnimateTransition.pageChangeOut()
      })
    },
  }
)

/**
 * Exports
 */
AnimateTransition.intro = intro
AnimateTransition.removeIntro = removeIntro
AnimateTransition.pageChangeIn = pageChangeIn
AnimateTransition.pageChangeOut = pageChangeOut

export default AnimateTransition
