// react
import * as React from "react"

// fetchers
import fetchDirectusImages from "./directus-images"

// data
import sectorsJSON from "../../client/data/sectors"

// utility
import by from "../../core/utility/by"
import indexByField from "../../core/utility/index-by-field"
import memoize from "../../core/utility/memoize"

/**
 * Normalize queries to directus projects collection.
 */
async function fetchDirectusProjects({ directus: client }) {
  // project data
  let { data } = await client.getItems("projects")

  // get image files
  const { data: files } = await client.api.get("/files")
  let { images } = await fetchDirectusImages({ directus: client })
  images = images
    .map(image =>
      Object.assign({}, image, files.find(file => file.id === image.id) || {})
    )
    .map(image => {
      // get last digits at end of title, and use as image order
      let match = (image.title || "").match(/[\d]+$/)
      image.order = match !== null ? parseInt(match[0], 10) : 0

      return image
    })
  const imagesById = indexByField("id", images)

  // project sectors
  const { data: projects_sectors } = await client.getItems("projects_sectors")
  const sectorsById = indexByField("id", sectorsJSON)

  data = data.map(project => {
    project.sectors = projects_sectors
      // get project sectors
      .filter(by("projects_id", project.id))
      // convert id to item
      .map(({ sectors_id }) => sectorsById[sectors_id])
      // filter out missing sectors
      .filter(sector => typeof sector !== "undefined")
      // pick fields from item
      .map(sector => ({
        slug: sector.slug,
        title: sector.title,
      }))
    return project
  })

  // project images
  let { data: projects_images } = await client.getItems(
    "projects_directus_files"
  )

  data = data.map(project => {
    project.images = projects_images
      // get project images
      .filter(by("projects_id", project.id))
      // convert object to id
      .map(({ directus_files_id }) => directus_files_id)
    return project
  })

  // result
  const result = {
    projects: data.map(result => ({
      slug: result.slug,
      title: result.project_name,
      category: result.category,
      role: result.role,
      location: result.location,
      client: result.client,
      year: result.year,
      bua: result.bua,
      keywords: result.keywords,
      sectors: result.sectors,
      images: [result.featured_image]
        .concat(result.images)
        .filter(imageId => typeof imageId === "number")
        .map(imageId => imagesById[imageId])
        .sort((a, b) => a.order - b.order),
      content: result.description,
    })),
  }

  return result
}

/**
 * Exports
 */
export default memoize(fetchDirectusProjects)
