// config
import { EVENTS } from "../../client/config"

// react
import React from "react"

// uniqid
import uniqid from "uniqid"

// utility
import triggerEvent from "../../core/utility/trigger-event"

/**
 * withDataAsync()
 */
const withDataAsync = function(
  WrappedComponent,
  fetcher,
  formatter,
  ...sources
) {
  /**
   * Use the fetchers to fetch data with a fetcher() function
   */
  const StateHandler = class extends React.Component {
    fetcher() {
      console.warn("withDataAsync() no fetcher given")
    }

    constructor(props) {
      super(props)

      this.state = { data: {} }

      if (typeof fetcher === "function") {
        this.fetcher = fetcher.bind(this)
      }
    }

    componentDidMount() {
      const { fetchers } = this.props

      const keys = Object.keys(fetchers)
      const clients = Object.values(fetchers)

      const errHandler = err => console.error("withDataAsync() error", err)

      // wait for all loader promises to resolve
      Promise.all(clients)
        .catch(errHandler)
        .then(() => {
          // run the fetcher
          const promise = this.fetcher(fetchers)

          if (!(promise instanceof Promise)) {
            throw new TypeError("fetcher must return promise")
          }

          const id = uniqid("data-async-")
          triggerEvent(EVENTS.ASYNC_LOAD_START, { id })

          promise.catch(errHandler).then((...results) => {
            const data = {}

            keys.forEach((key, i) => {
              data[key] = results[i]
            })

            this.setState({ data })

            requestAnimationFrame(() => {
              triggerEvent(EVENTS.ASYNC_LOAD_END, { id })
            })
          })
        })
    }

    render() {
      const { fetchers, data: dataFromProps, ...passthruProps } = this.props
      const { data: dataFromState } = this.state

      let data = Object.assign({}, dataFromProps, dataFromState)

      // format data
      if (typeof formatter === "function") {
        data = formatter(data)
      }

      return <WrappedComponent {...passthruProps} data={data} />
    }
  }

  /**
   * Execute loader queries and collect the result in a loader object
   */
  // must be function component to use React Hooks
  return props => {
    // get fetchers from props
    const { fetchers: passthruFetchers, ...passthruProps } = props
    let fetchers = Object.assign({}, passthruFetchers)

    // add fetchers from sources
    for (let i = 0; i < sources.length; i++) {
      let concatFetchers = sources[i]
      if (typeof concatFetchers === "function")
        concatFetchers = concatFetchers()

      Object.assign(fetchers, concatFetchers)
    }

    // add to wrapped component
    return <StateHandler {...passthruProps} fetchers={fetchers} />
  }
}

/**
 * Exports
 */
export default withDataAsync
