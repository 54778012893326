/**
 *  Imports
 */
import React from "react"

/**
 * withPairedComponent()
 *
 * Connect two components such that they can call each other's methods.
 *
 * const Enhanced = withPairedComponent(
 * // first component
 * class extends React.Component {},
 *
 * // second component
 * class extends React.Component {},
 *
 * // format incoming props to firstProps/secondProps for friendlier prop naming
 * ({aProps: firstProps, bProps: secondProps}) => ({firstProps, secondProps}),
 *
 * // set props/methods on first component
 * // WARNING: only methods set here are bound correctly
 * {
 *   className: "first_component_classname",
 *   onClick: function () {
 *    const [first, second] = this.getPairedComponents()
 *    const bClass = second.props.className
 *   }
 * },
 *
 * // set props/methods on second component
 * // WARNING: only methods set here are bound correctly
 * {
 *   className: "second_component_classname",
 *   onClick: function () {
 *    const [first, second] = this.getPairedComponents()
 *    const aClass = first.props.className
 *   }
 * })
 *
 */
const withPairedComponent = function(
  FirstComponent,
  SecondComponent,
  formatter,
  defaultFirstProps = {},
  defaultSecondProps = {}
) {
  return class extends React.Component {
    /**
     * constructor
     */

    constructor(props) {
      super(props)

      this.firstRef = React.createRef()
      this.secondRef = React.createRef()

      // process props
      const allDefaultProps = {
        defaultFirstProps,
        defaultSecondProps,
      }

      Object.keys(allDefaultProps).forEach(defaultKey => {
        const defaultProps = allDefaultProps[defaultKey]
        this[defaultKey] = {}

        Object.keys(defaultProps).forEach(key => {
          const prop = defaultProps[key]

          switch (typeof prop) {
            case "function":
              this[defaultKey][key] = prop.bind(this)
              break

            default:
              this[defaultKey][key] = prop
              break
          }
        })
      })
    }

    /**
     * getPairedComponents
     */
    getPairedComponents() {
      return [this.firstRef.current, this.secondRef.current]
    }

    /**
     * render
     */
    render() {
      let { firstProps, secondProps } = this.props

      if (typeof formatter === "function")
        ({ firstProps, secondProps } = formatter(this.props))

      let allFirstProps = Object.assign(
        {},
        this.defaultFirstProps,
        firstProps,
        {
          ref: this.firstRef,
        }
      )

      let allSecondProps = Object.assign(
        {},
        this.defaultSecondProps,
        secondProps,
        {
          ref: this.secondRef,
        }
      )

      return (
        <>
          <FirstComponent {...allFirstProps} />
          <SecondComponent {...allSecondProps} />
        </>
      )
    }
  }
}

/**
 * Exports
 */
export default withPairedComponent
