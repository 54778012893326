import React from "react"
import classNames from "react-css-module-classnames"

// dompurify
import DOMPurify from "dompurify"

// components
import Heading from "../../components/semantic/heading"

// data
import countries from "../../../client/data/countries.json"

const inputParsers = {
  uppercase(input) {
    return (input || "").toUpperCase()
  },
  email(input) {
    return (input || "").toLowerCase()
  },
  telephone(input) {
    return (input || "").replace(/[^0-9\(\)\-\+]/g, "")
  },
  url(input) {
    try {
      return new URL(input).toString() || ""
    } catch (err) {
      return ""
    }
  },
}

/**
 * <ContactForm />
 * @see https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f
 */
class ContactForm extends React.Component {
  /**
   * Constructor
   */

  constructor() {
    super()

    // default state
    this.state = {
      result: null,
      msg: null,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit(event) {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)

    form.classList.add("submitted")

    for (let name of data.keys()) {
      const input = form.elements[name]
      const parserName = input.dataset.parse

      if (parserName) {
        const parser = inputParsers[parserName]
        const parsedValue = parser(data.get(name))
        data.set(name, parsedValue)
      }
    }

    const payload = {}
    for (let key of data.keys()) {
      payload[key] = data.get(key)
    }

    if (!payload["agree"])
      return alert(
        "You must agree with our terms and conditions to submit this form."
      )

    let response = {
      result: "error",
      msg: "No handler defined",
    }
    if (typeof this.props.handler === "function") {
      console.log("Submitting form with payload", payload)

      response = await this.props.handler(payload)
    }

    let { result, msg } = response

    // sanitize response
    if (!["success", "error"].includes(result)) {
      console.warn("weird response", result, msg)
      result = "error"
      msg = "Something went wrong. Please try again later."
    }

    this.setState({
      result,
      msg: DOMPurify.sanitize(msg),
    })
  }

  render() {
    const { result, msg } = this.state
    return (
      <form
        onSubmit={this.onSubmit}
        noValidate
        {...classNames("contact").plus(result)}
      >
        <fieldset>
          <Heading level={4}>Company</Heading>
          <p>
            <label>
              <span>Company Name</span>
              <input name="company_name" type="text" />
            </label>
          </p>

          <p>
            <label>
              <span>Type</span>
              <select name="company_type">
                <option value="">-- SELECT --</option>
                <option>Supply</option>
                <option>Apply</option>
                <option>Services</option>
              </select>
            </label>
          </p>

          <p>
            <label>
              <span>Scope Category</span>
              <input name="company_scope_category" type="text" />
            </label>
          </p>

          <br />

          <p>
            <label>
              <span>Address</span>
              <input name="company_address" type="text" />
            </label>
          </p>

          <p>
            <label>
              <span>Country</span>
              <select name="company_country">
                <option value="">-- SELECT --</option>
                {countries.map(({ name, code }) => (
                  <option key={code}>{name}</option>
                ))}
              </select>
            </label>
          </p>

          <p>
            <label>
              <span>Postal Code</span>
              <input
                name="company_postcode"
                type="text"
                data-parse="uppercase"
              />
            </label>
          </p>

          <p>
            <label>
              <span>Website</span>
              <input
                name="company_website"
                type="url"
                data-parse="url"
                placeholder="https://www.yourwebsite.com/"
              />
            </label>
          </p>
        </fieldset>
        <fieldset>
          <Heading level={4}>Contact</Heading>
          <p class="contact-name">
            <label>
              <span>Full Name</span>
              <input name="contact_name" type="text" />
            </label>
          </p>

          <p class="role">
            <label>
              <span>Job Title</span>
              <input name="contact_role" type="text" />
            </label>
          </p>

          <p class="email">
            <label>
              <span>
                Email <strong class="required">*</strong>
              </span>
              <input name="email" type="email" required data-parse="email" />
            </label>
          </p>

          <p class="telephone">
            <label>
              <span>Telephone</span>
              <input name="telephone" type="tel" data-parse="telephone" />
            </label>
          </p>
        </fieldset>

        <fieldset style={{ marginTop: 0 }}>
          <p className="message">
            <label>
              <span>
                Message <strong class="required">*</strong>
              </span>
              <textarea rows="8" name="message" required></textarea>
            </label>
          </p>

          <p className="agree">
            <label>
              <input type="checkbox" name="agree" required />
              <span>I agree to Privacy Policy, Terms & Conditions</span>
            </label>
          </p>

          <div className="submit">
            {result !== "success" ? (
              <>
                {result === "error" && msg && (
                  <span class="error-msg">{msg}</span>
                )}
                <button type="submit">Submit</button>
              </>
            ) : (
              <span class="success-msg">{msg}</span>
            )}
          </div>
        </fieldset>
      </form>
    )
  }
}

export default ContactForm
