// components
import { default as NewsletterComponent } from "../../core/components/forms/newsletter"

// hocs
import withHandler from "../../core/hocs/handler"

// handlers
import addToMailchimp from "gatsby-plugin-mailchimp"

/**
 * Create newsletter with mailchimp service.
 */
const Newsletter = withHandler(NewsletterComponent, null, addToMailchimp)

/**
 * Exports
 */
export default Newsletter
