// react
import * as React from "react"

// hocs
import withDataAsync from "../../core/hocs/data-async"

// components
import Anchor from "../../core/components/semantic/anchor"
import GeneratedBlock from "../../core/components/grid/generated-block"
import Heading from "../../core/components/semantic/heading"
import Marquee from "../../core/components/content/marquee"

// queries
import useDirectus from "../queries/directus"

// fetchers
import fetchDirectusNews from "../fetchers/directus-news"

/**
 * Show a list of news stories as a marquee
 */
const BlockNewsMarquee = withDataAsync(
  GeneratedBlock,
  fetchDirectusNews,
  ({ directus: result }) => ({
    elements: [
      <Marquee speed={25} key={-1}>
        {result &&
          result.news.map((story, i) => (
            <React.Fragment key={i}>
              <Heading className="anchor">Our News</Heading>
              <Anchor url={story.url}>{story.title}</Anchor>
            </React.Fragment>
          ))}
      </Marquee>,
    ],
  }),
  useDirectus
)

/**
 * Exports
 */
export default BlockNewsMarquee
