// react
import * as React from "react"

// hocs
import withDataAsync from "../../core/hocs/data-async"

// components
import Anchor from "../../core/components/semantic/anchor"
import GeneratedBlock from "../../core/components/grid/generated-block"
import Heading from "../../core/components/semantic/heading"
import Paragraph from "../../core/components/semantic/paragraph"

// compositions
import { GeneratedBlock as AnimateIntroGeneratedBlock } from "./animate-intro"

// queries
import useDirectus from "../queries/directus"

// fetchers
import fetchDirectusNews from "../fetchers/directus-news"

/**
 * Show a list of news stories.
 */
const BlockNews = withDataAsync(
  AnimateIntroGeneratedBlock,
  fetchDirectusNews,
  ({ directus: result }) => ({
    elements: [
      <Heading level={2} key={-1} className="no_animation">
        News
      </Heading>,
    ].concat(
      result &&
        result.news.map((story, i) => {
          return (
            <div className="story intro_from_below" key={i}>
              <Heading level={3} className="no_animation">
                {story.title}
              </Heading>
              <Paragraph className="no_animation">{story.content}</Paragraph>
              <Anchor url={story.url}>
                Read on <b>{story.siteName}</b>
              </Anchor>
            </div>
          )
        })
    ),
  }),
  useDirectus
)

/**
 * Exports
 */
export default BlockNews
