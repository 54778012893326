/**
 * Newsletter
 *
 * Standard newsletter signup form.
 */

/**
 * Imports
 */
// react
import React from "react"
import PropTypes from "prop-types"
import classNames from "react-css-module-classnames"

// formik
import { Formik, Form, Field, ErrorMessage } from "formik"

// dompurify
import DOMPurify from "dompurify"

// components
import Heading from "../semantic/heading"

// id ticker
let idTicker = 0

/**
 * <Newsletter />
 */
export default class Newsletter extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    // inherit
    super(props)

    // default values
    this.initialValues = {
      email: "",
    }

    // default state
    this.state = {
      result: null,
      msg: null,
    }

    // bind handlers
    this.validate = this.validate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  /**
   * validate
   * Called with form values on form change.
   */
  validate(values) {
    let errors = {}
    if (!values.email) {
      errors.email = this.props.errRequiredText
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = this.props.errInvalidText
    }
    return errors
  }

  /**
   * onSubmit
   * Called when form is submitted.
   */
  async onSubmit(values, { setSubmitting, setStatus }) {
    const { email } = values

    // add with external service
    let { result, msg } = await this.props.handler(email)

    // sanitize response
    if (!["success", "error"].includes(result)) {
      console.warn("weird response", result, msg)
      result = "error"
      msg = "Something went wrong. Please try again later."
    }

    let response = {
      result,
      msg: DOMPurify.sanitize(msg),
    }

    // update state
    this.setState(response)

    // re-enable form
    setSubmitting(false)

    // handle error
    switch (result) {
      default:
      case "error":
        this.onError(msg)
        break

      case "success":
        this.onSuccess(msg)
        break
    }
  }

  /**
   * onSuccess
   * Called when service responds with result: success
   */
  onSuccess(msg) {
    // handle custom success message
    const { successText } = this.props
    msg = successText || msg
    this.setState({ msg })

    // optional sexy stuff
  }

  /**
   * onError
   * Called when service responds with result: error
   */
  onError(msg) {
    // optional sexy stuff
  }

  /**
   * Render
   */
  render() {
    // form options
    const { title, inputLabelText, placeholderText, submitText } = this.props

    // get id
    // (backward compatible) when idTicker = 0, id must be "newsletter"
    let id = idTicker ? `newsletter_${idTicker}` : `newsletter`
    idTicker++

    if (typeof this.props.id === "string") {
      id = this.props.id
    }

    // formik wiring
    const formikProps = {
      initialValues: this.initialValues,
      validate: this.validate,
      onSubmit: this.onSubmit,
    }

    // get service result
    let { result, msg } = this.state

    // return form
    return (
      <section id={id} data-result={result} {...classNames("newsletter")}>
        <Heading>{title}</Heading>
        <Formik {...formikProps}>
          {({ isSubmitting }) => (
            <Form>
              <span className="field_wrapper" id={`${id}_field_wrapper_email`}>
                <label htmlFor={`${id}_field_email`}>{inputLabelText}</label>
                <Field
                  type="email"
                  id={`${id}_field_email`}
                  name="email"
                  placeholder={placeholderText}
                  onFocus={() => this.setState({ result: null })}
                  autoComplete="off"
                />
                <ErrorMessage name="email" component="span" />
              </span>
              <input type="submit" disabled={isSubmitting} value={submitText} />

              <div
                dangerouslySetInnerHTML={{ __html: msg }}
                {...classNames("response")}
              />
            </Form>
          )}
        </Formik>
      </section>
    )
  }
}

Newsletter.defaultProps = {
  title: `Subscribe to our newsletter`,
  inputLabelText: `Email`,
  placeholderText: `Enter your email...`,
  submitText: `Subscribe`,
  errRequiredText: ``,
  errInvalidText: `Please enter a valid email address`,
  successText: ``, // leave blank to use service response
}

Newsletter.propTypes = {
  title: PropTypes.string,
  inputLabelText: PropTypes.string,
  placeholderText: PropTypes.string,
  submitText: PropTypes.string,
  errRequiredText: PropTypes.string,
  errInvalidText: PropTypes.string,
  successText: PropTypes.string,
  handler: PropTypes.func.isRequired,
}
