// config
import { ANIMATE_APPEAR, EVENTS } from "../config"

// react
import * as React from "react"

// components
import AnchorComponent from "../../core/components/semantic/anchor"
import CarouselComponent from "../../core/components/content/carousel"
import BlockComponent from "../../core/components/grid/block"
import GeneratedBlockComponent from "../../core/components/grid/generated-block"
import RowComponent from "../../core/components/grid/row"
import WrapperComponent from "../../core/components/semantic/wrapper"

// component factories
import IntroGSAPFactory from "../../core/components-factory/intro-gsap"

// utility
import callWhenIdle from "../../core/utility/call-when-idle"
import triggerEvent from "../../core/utility/trigger-event"

/**
 * Wire up default intro animation
 */
const withDefaultIntro = (
  Component,
  gsapOpts = {},
  targets = {},
  ignoreDefaultTargets = false,
  trigger = "intersect"
) =>
  IntroGSAPFactory(
    Component,
    ANIMATE_APPEAR.TOLERANCE,
    Object.assign(
      {},
      {
        duration: ANIMATE_APPEAR.DURATION,
        delay: ANIMATE_APPEAR.DELAY,
        stagger: ANIMATE_APPEAR.STAGGER,
        targets: Object.assign(
          ignoreDefaultTargets
            ? {}
            : {
                // stagger left helper
                ".intro_from_left": {
                  from: ANIMATE_APPEAR.LEFT,
                },
                // stagger up
                ".heading:not([data-level='4']):not(.no_animation):not(.intro_from_left), .paragraph:not(.no_animation), .intro_from_below, header > .decoration": {
                  from: ANIMATE_APPEAR.BELOW,
                },
                // stagger list
                ".stagger_list > *": {
                  from: ANIMATE_APPEAR.BELOW,
                  delay: 1,
                },
                // decoration fade in
                ".decoration_block .decoration:not(.no_animation)": {
                  from: ANIMATE_APPEAR.INVISIBLE,
                  // duration: 0.75,
                  ease: "expo.in",
                },
                // share page
                ".share_page li": {
                  from: ANIMATE_APPEAR.BELOW,
                  delay: ANIMATE_APPEAR.STAGGER,
                },
              },
          targets
        ),
      },
      gsapOpts
    ),
    trigger
  )

/**
 * <AnimateIntroRow />
 */
export const Row = withDefaultIntro(RowComponent)

/**
 * <AnimateIntroGeneratedBlock />
 */
export const GeneratedBlock = withDefaultIntro(GeneratedBlockComponent)

/**
 * <AnimateIntroBlock />
 */
export const Block = withDefaultIntro(BlockComponent)

/**
 * <AnimateIntroWrapper />
 */
export const Wrapper = withDefaultIntro(WrapperComponent)

/**
 * <AnimateIntroSelf />
 */
export const Self = withDefaultIntro(
  WrapperComponent,
  { duration: 0.75, delay: 0, ease: "expo.in" },
  { self: { from: ANIMATE_APPEAR.INVISIBLE } },
  true
)

/**
 * <AnimateIntroCarousel />
 */
export const Carousel = CarouselComponent

/**
 * <AnimateIntroProjectAnchor />
 */
export const ProjectAnchor = withDefaultIntro(
  AnchorComponent,
  {},
  {
    ".heading[data-level='3'], .decoration, .list": {
      from: ANIMATE_APPEAR.LEFT,
    },
    ".list .item": {
      from: ANIMATE_APPEAR.BELOW,
      delay: 0.5,
    },
  },
  true
)

/**
 * <AnimateIntroBanner />
 */
export const Banner = withDefaultIntro(
  RowComponent,
  {},
  {
    ".heading, .paragraph, .list .item, .learn_more": {
      from: Object.assign({}, ANIMATE_APPEAR.BELOW, ANIMATE_APPEAR.INVISIBLE),
    },
    ".flickity-button": {
      from: ANIMATE_APPEAR.INVISIBLE,
      delay: ANIMATE_APPEAR.STAGGER * 4,
      stagger: 0,
    },
    "#sectors_carousel .cell": {
      from: ANIMATE_APPEAR.RIGHT,
      stagger: {
        amount: ANIMATE_APPEAR.STAGGER * 4,
        from: "end",
      },
      delay: ANIMATE_APPEAR.STAGGER * 2,
    },
  },
  true,
  EVENTS.PAGE_CHANGE_END
)

/**
 * <AnimateIntroHeadline />
 * Used on service page.
 */
export const Headline = withDefaultIntro(
  RowComponent,
  {},
  {
    ".heading": {
      from: Object.assign({}, ANIMATE_APPEAR.LEFT, ANIMATE_APPEAR.INVISIBLE),
    },
    ".decoration": {
      from: Object.assign({}, ANIMATE_APPEAR.BELOW, ANIMATE_APPEAR.INVISIBLE),
      delay: ANIMATE_APPEAR.STAGGER * 2,
    },
  },
  true
)

/**
 * <AnimateIntroListBlock />
 * Used on about page.
 */
export const ListBlockItem = IntroGSAPFactory(WrapperComponent, "-50%", {
  duration: ANIMATE_APPEAR.DURATION,
  delay: 0,
  stagger: 0,
  opacity: 1,
  targets: {
    ".number": {
      from: Object.assign({}, ANIMATE_APPEAR.SCALE, ANIMATE_APPEAR.VISIBLE),
    },
    ".heading, .paragraph": {
      from: Object.assign({}, ANIMATE_APPEAR.BELOW, ANIMATE_APPEAR.INVISIBLE),
    },
  },
})
