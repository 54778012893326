// react
import * as React from "react"

// components
import { Line } from "../components"

/**
 * Generate HTML for vertical background lines
 */
const RowLines = props => (
  <>
    <div className="lines">
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </div>
    {!props.disableScrollIndicator && (
      <Line direction="ttb" className="simple_loop scroll_indicator" />
    )}
  </>
)

/**
 * Exports
 */
export default RowLines
