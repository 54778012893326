/** Parallax effet strength */
const STRENGTH = 1001
/** Parallax effect duration (s) */
const DURATION = 0.75

// config
import { EVENTS } from "../config"

// react
import * as React from "react"

// gsap
import gsap from "gsap"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

// utility
import callWhenIdle from "../../core/utility/call-when-idle"
import getCrossBrowserWindowValues from "../../core/utility/get-cross-browser-window-values"
import getTranslateY from "../../core/utility/get-translate-y"
import isMobile from "../../core/utility/is-mobile"

/**
 * Fade background in and out
 */
const AnimateParallax = withProps(Listener, null, {
  init: on => {
    let parallaxItems = []

    let win = getCrossBrowserWindowValues()

    const calcDelta = pos => (pos / win.scroll.maxTop) * STRENGTH * -1

    /**
     * Get all parallax elements on page
     */
    const detectParallaxItems = () => {
      parallaxItems = Array.from(
        document.querySelectorAll(
          ".parallax, .decoration_block .decoration_wrapper"
        )
      ).map(item => {
        Object.assign(item.style, {
          position: "relative",
          top: "0px",
          "will-change": "transform",
          "backface-visibility": "hidden",
        })

        return item
      })
    }

    /**
     * Offset based on scroll position
     */
    const detectBounds = () => {
      if (!parallaxItems.length) return

      // reset
      parallaxItems.forEach(item => (item.style.top = "0px"))
      gsap.set(parallaxItems, { y: 0 })

      // don't enable parallax on mobile
      if (isMobile()) return

      console.log("detecting parallax")

      // set initial offset
      requestAnimationFrame(() => {
        parallaxItems.forEach(item => {
          const naturalTop =
            win.scroll.top +
            item.getBoundingClientRect().top -
            getTranslateY(item)
          const initialOffset = calcDelta(naturalTop) * -1

          item.style.top = `${initialOffset}px`
        })

        // set tweenmax offset without animation
        gsap.set(parallaxItems, {
          y: calcDelta(win.scroll.top),
        })
      })
    }

    /**
     * Offset based on scroll position
     */
    const setParallaxOffset = () => {
      if (!parallaxItems.length) return
      if (isMobile()) return

      gsap.to(parallaxItems, {
        duration: DURATION,
        y: calcDelta(win.scroll.top),
        ease: "power4.out",
      })
    }

    /**
     * Setup listeners
     */
    // init
    const init = () => {
      detectParallaxItems()
      detectBounds()
    }

    // handle resize
    on(EVENTS.FORCE_REDETECT, () => callWhenIdle(init))
    on("resize", detectBounds)

    // handle scroll
    on("scroll", setParallaxOffset, { throttle: { delay: 100 } })
  },
})

/**
 * Exports
 */
export default AnimateParallax
