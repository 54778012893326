// hocs
import withProps from "../../core/hocs/props"

// components
import Carousel from "../../core/components/content/carousel"

/**
 * Show a paragraph slider.
 */
const ParagraphSlider = withProps(Carousel, {
  scrollBar: false,
  flickityProps: {
    options: {
      prevNextButtons: true,
      pageDots: false,
      contain: true,
      fade: true,
    },
  },
  carouselClassName: "paragraph_slider",
})

/**
 * Exports
 */
export default ParagraphSlider
