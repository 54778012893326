// config
import { EVENTS, ANIMATE_BACKGROUND_FADE } from "../config"

// react
import * as React from "react"

// hocs
import withProps from "../../core/hocs/props"

// components
import Listener from "../../core/components/misc/listener"

// utility
import getCrossBrowserWindowValues from "../../core/utility/get-cross-browser-window-values"

/**
 * Fade background in and out
 */
const AnimateBackgrounds = withProps(Listener, null, {
  init: on => {
    let rows = []

    /**
     * Get all row elements on page
     * Prepare to animate their backgrounds
     */
    const setupRows = () => {
      rows = Array.from(
        document.querySelectorAll(
          ".row:not([data-background-animation='false']):not(.footer_marquee):not(#share)"
        )
      )
        // apply animation styles to row backgrounds
        .map(row => {
          row.bg = row.querySelector(".background")

          if (!row.bg) {
            return row
          }

          Object.assign(row.style, {
            minHeight: "100vh",
            transition: `opacity ${ANIMATE_BACKGROUND_FADE.DURATION}s`,
          })

          Object.assign(row.bg.style, {
            position: "fixed",
            "z-index": 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: "100vh",
            opacity: 0,
            transition: `opacity ${ANIMATE_BACKGROUND_FADE.DURATION}s`,
          })

          return row
        })
        // remove background colors
        .map(row => {
          row.style["background-color"] = "transparent"
          return row
        })
        // ignore rows without backgrounds
        .filter(row => row.bg)
    }

    /**
     * Detect the row positions
     */
    const detectBounds = () => {
      const { scroll } = getCrossBrowserWindowValues()

      rows = rows.map(row => {
        row.rectTop = row.getBoundingClientRect().top + scroll.top
        return row
      })
    }

    /**
     * Show the correct background for the current scroll position
     */
    const showCurrentBackground = () => {
      const { scroll, size } = getCrossBrowserWindowValues()

      let offset = size.height * 0.5
      let pos = scroll.top + offset
      let shownRow = null

      rows.forEach(row => {
        if (pos >= row.rectTop) {
          shownRow = row
        }
      })

      if (shownRow) {
        // set background color to make nicer fades
        const bgColor = getComputedStyle(shownRow.bg).backgroundColor
        document.body.style[
          "transition"
        ] = `background-color ${ANIMATE_BACKGROUND_FADE.DURATION}s`
        document.body.style["background-color"] = bgColor || "white"

        // hide all row backgrounds
        rows.forEach(row => {
          row.style.opacity = 0
          row.bg.style.opacity = 0
        })

        // show current row background
        shownRow.style.opacity = 1
        shownRow.bg.style.opacity = 1
      }
    }

    /**
     * Setup listeners
     */
    // init
    const init = () => {
      setupRows()
      detectBounds()
      showCurrentBackground()
    }

    on(EVENTS.ROUTE_UPDATE, init)
    on(EVENTS.ASYNC_LOAD_END_ALL, init)

    // handle resize
    on(
      EVENTS.FORCE_REDETECT,
      () =>
        requestAnimationFrame(detectBounds) && setTimeout(detectBounds, 2000)
    )
    on("resize", detectBounds)

    // handle scroll
    on("scroll", showCurrentBackground)
  },
})

/**
 * Exports
 */
export default AnimateBackgrounds
