/**
 * useVideosDirect()
 */

/**
 * Imports
 */

// gatsby
import { useStaticQuery, graphql } from "gatsby"

// utility
import orderedBy from "../../core/utility/ordered-by"

/**
 * VideosDirectQuery
 */
function useVideosDirect(filter) {
  const data = useStaticQuery(graphql`
    query VideosDirectQuery {
      allFile(
        filter: { extension: { glob: "(mp4|webm)" } }
        sort: { order: ASC, fields: size }
      ) {
        nodes {
          relativePath
          name
          extension
          publicURL
        }
      }
    }
  `)

  return {
    videosDirect: data.allFile.nodes
      .filter(typeof filter === "function" ? filter : () => true)
      .sort(orderedBy("extension", ["hevc", "ogg", "webm", "mp4"]))
      .map(props => {
        // extract width from filename
        const match = props.name.match(/^(.*)-([\d]+)$/)
        if (match !== null) {
          const [, name, width] = match
          props.name = name
          props.width = parseInt(width, 10)
        }

        // return name, src, type and width
        return {
          name: props.name,
          src: props.publicURL,
          type: `video/${props.extension}`,
          width: props.width,
        }
      }),
  }
}

/**
 * Exports
 */
export default useVideosDirect
