// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// gsap
import gsap from "gsap"

// lodash
import throttle from "lodash/throttle"

// utility
import queryData from "../../utility/query-data"
import flatten from "../../utility/flatten"
import closest from "../../utility/closest"

// <Video />
type Props = {
  /** Component data, perhaps provided by withData() */
  data: {
    /** Array of videos for component to reference */
    videos: Array<{
      /** Video name (referenced by component) */
      name: string,
      /** Video src */
      src: string,
      /** Video type */
      type?: string,
      /** Video width */
      width?: number,
      ...
    }>,
  },
  /** A list of sources for the <video> element */
  sources: Array<{
    /** Video src */
    src: string,
    /** Video type */
    type?: string,
    /** Video width */
    width?: number,
    ...
  }>,
  /** Reference to video name in data.videos (ignored if sources is provided) */
  video: string,
  /** Enable autoPlay */
  autoPlay: boolean,
  /** Enable loop */
  loop: boolean,
  /** Start muted */
  muted: boolean,
  /** Enable controls */
  controls: boolean,
  /** Play button content */
  playButton: string | React.Node,
  /** Pause button content  */
  pauseButton: string | React.Node,
  /** Mute button content */
  muteButton: string | React.Node,
  /** Unmute button content */
  unmuteButton: string | React.Node,
  /** Thumbnail */
  thumbnail?: React.Node,
  /** Animation tweens  */
  gsapAnimations: {
    playButton?: {
      in: Object,
      out: Object,
    },
    pauseButton?: {
      in: Object,
      out: Object,
    },
    muteButton?: {
      in: Object,
      out: Object,
    },
    unmuteButton?: {
      in: Object,
      out: Object,
    },
  },
  /** Custom class for video element */
  videoClassName?: string,
  /** Custom class for video wrapper element */
  videoWrapperClassName?: string,
  /** Custom class for controls element */
  controlsClassName?: string,
  /** Custom class for play button element */
  playButtonClassName?: string,
  /** Custom class for pause button element */
  pauseButtonClassName?: string,
  /** Custom class for mute button element */
  muteButtonClassName?: string,
  /** Custom class for unmute button element */
  unmuteButtonClassName?: string,
  /** Custom class for root element */
  className?: string,
}

type State = {
  sources: Array<Object>,
}

/**
 * Video Player
 *
 * Fully customisible and animatable video player, which adapts sources to
 * window size.
 *
 * Custom content and GSAP animation tween can be defined for each component.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .video           | Root element                                           |
 * | .video_wrapper   | <video> container                                      |
 * | .controls        | Control elements container                             |
 * | .play_button     | Play button element                                    |
 * | .pause_button    | Pause button element                                   |
 * | .mute_button     | Mute button element                                    |
 * | .unmute_button   | Unmute button element                                  |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/video.scss
 */
class Video extends React.Component<Props, State> {
  static defaultProps = {
    data: {
      videos: [],
    },
    autoPlay: false,
    muted: false,
    loop: false,
    controls: true,
    playButton: "Play",
    pauseButton: "Pause",
    muteButton: "🔈",
    unmuteButton: "🔊",
    gsapAnimations: {},
  }

  state = {
    sources: [],
  }

  // object props
  sourcesByWidth: Object

  // refs
  self: Element | null
  videoRef: { current: HTMLVideoElement } | { current: null }

  // custom methods

  /**
   * Get sources & index by width
   * Either from "sources" prop or by name from list of videos
   */
  getSources() {
    let { data, sources, video } = this.props

    // get sources
    if (!sources || !sources.length) {
      sources = queryData(data.videos)
        .getAll()
        .where("name")
        .is(video)
    }

    // index sources by width
    this.sourcesByWidth = {}

    sources
      .filter(source => source.width)
      .forEach(source => {
        if (!(this.sourcesByWidth[source.width] instanceof Array)) {
          this.sourcesByWidth[source.width] = []
        }

        this.sourcesByWidth[source.width].push(source)
      })
  }

  /** set sources state with sources that fit the screen */
  adaptSourcesToScreen() {
    // if no window width, return all cause we're probably testing
    if (!window) {
      const allSources = flatten(this.sourcesByWidth)
      this.setState({ sources: allSources })
    }

    // get video node
    const videoNode = this.videoRef && this.videoRef.current

    // don't switch sources when we're playing or paused at non-zero time
    if (videoNode && this.state.sources.length) {
      if (!videoNode.paused) return
      if (videoNode.currentTime) return
    }

    // otherwise, get the closest width to window width
    const targetWidth = window.innerWidth
    const widths = Object.keys(this.sourcesByWidth)
    const closestWidth = closest(targetWidth, widths)

    this.setState({ sources: this.sourcesByWidth[closestWidth] })
  }

  /** Play video */
  play() {
    // get video node
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    // play in full screen on small devices
    const enterFullscreen =
      //$FlowFixMe
      videoNode.webkitSupportsFullscreen && videoNode.webkitEnterFullscreen
    if (
      typeof enterFullscreen === "function" &&
      window.screen.availWidth < 1024
    ) {
      enterFullscreen()
    }

    videoNode.play()
  }

  /** Pause video */
  pause() {
    // get video node
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    videoNode.pause()
  }

  /** Toggle video */
  toggle() {
    // get video node
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    // play if paused
    if (videoNode.paused) {
      return this.play()
    }

    // pause if playing
    this.pause()
  }

  /** Mute video */
  mute() {
    // get video node
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    // mute video
    videoNode.muted = true
    this.handleVideoStateChange()
  }

  /** Unmute video */
  unmute() {
    // get video node
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    // unmute video
    videoNode.muted = false
    this.handleVideoStateChange()
  }

  /** Handle video state changes */
  handleVideoStateChange() {
    // get self/video nodes
    const node = this.self
    const videoNode = this.videoRef && this.videoRef.current
    if (!node || !videoNode) return

    // animate according to tween data
    let { gsapAnimations } = this.props

    if (gsapAnimations) {
      // get button elements
      const playButton = node.querySelector(".play_button")
      const pauseButton = node.querySelector(".pause_button")
      const muteButton = node.querySelector(".mute_button")
      const unmuteButton = node.querySelector(".unmute_button")

      // get button tweens
      const {
        playButton: playSegments,
        pauseButton: pauseSegments,
        muteButton: muteSegments,
        unmuteButton: unmuteSegments,
      } = Object.assign(
        {
          playButton: {},
          pauseButton: {},
          muteButton: {},
          unmuteButton: {},
        },
        gsapAnimations
      )

      // set default tweens
      if (!playSegments.in) {
        playSegments.in = { display: "block", opacity: 1, duration: 0.5 }
      }
      if (!playSegments.out) {
        playSegments.out = { display: "none", opacity: 0, duration: 0.5 }
      }
      if (!pauseSegments.in) {
        pauseSegments.in = { display: "block", duration: 0 }
      }
      if (!pauseSegments.out) {
        pauseSegments.out = { display: "none", duration: 0 }
      }
      if (!muteSegments.in) {
        muteSegments.in = { display: "block", duration: 0 }
      }
      if (!muteSegments.out) {
        muteSegments.out = { display: "none", duration: 0 }
      }
      if (!unmuteSegments.in) {
        unmuteSegments.in = { display: "block", duration: 0 }
      }
      if (!unmuteSegments.out) {
        unmuteSegments.out = { display: "none", duration: 0 }
      }
      // update play state
      if (!videoNode.paused) {
        if (playButton) {
          gsap.to(playButton, playSegments.out)
        }
        if (pauseButton) {
          gsap.to(pauseButton, pauseSegments.in)
        }
      } else {
        if (playButton) {
          gsap.to(playButton, playSegments.in)
        }
        if (pauseButton) {
          gsap.to(pauseButton, pauseSegments.out)
        }
      }

      // update mute state
      if (videoNode.muted) {
        if (muteButton) {
          gsap.to(muteButton, muteSegments.out)
        }
        if (unmuteButton) {
          gsap.to(unmuteButton, unmuteSegments.in)
        }
      } else {
        if (muteButton) {
          gsap.to(muteButton, muteSegments.in)
        }
        if (unmuteButton) {
          gsap.to(unmuteButton, unmuteSegments.out)
        }
      }
    }
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // create refs
    this.videoRef = React.createRef()

    // bind functions
    ;(this: any).getSources = this.getSources.bind(this)
    ;(this: any).adaptSourcesToScreen = throttle(
      this.adaptSourcesToScreen.bind(this),
      1000,
      { leading: false, trailing: true }
    )
    ;(this: any).play = this.play.bind(this)
    ;(this: any).pause = this.pause.bind(this)
    ;(this: any).toggle = this.toggle.bind(this)
    ;(this: any).mute = this.mute.bind(this)
    ;(this: any).unmute = this.unmute.bind(this)
    ;(this: any).handleVideoStateChange = this.handleVideoStateChange.bind(this)

    // get sources from props/data
    this.getSources()
  }

  componentDidMount() {
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    // add event listeners
    window && window.addEventListener("resize", this.adaptSourcesToScreen)
    videoNode.addEventListener("play", this.handleVideoStateChange)
    videoNode.addEventListener("pause", this.handleVideoStateChange)
    videoNode.addEventListener("volumechange", this.handleVideoStateChange)
    videoNode.addEventListener("seeking", this.handleVideoStateChange)
    videoNode.addEventListener("waiting", this.handleVideoStateChange)
    videoNode.addEventListener("playing", this.handleVideoStateChange)
    videoNode.addEventListener("seeked", this.handleVideoStateChange)
    videoNode.addEventListener("stalled", this.handleVideoStateChange)
    videoNode.addEventListener("suspend", this.handleVideoStateChange)
    videoNode.addEventListener("ended", this.handleVideoStateChange)

    // select initial best sources
    this.adaptSourcesToScreen()
  }

  componentWillUnmount() {
    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    // remove event listeners
    window && window.removeEventListener("resize", this.adaptSourcesToScreen)
    videoNode.removeEventListener("play", this.handleVideoStateChange)
    videoNode.removeEventListener("pause", this.handleVideoStateChange)
    videoNode.removeEventListener("volumechange", this.handleVideoStateChange)
    videoNode.removeEventListener("seeking", this.handleVideoStateChange)
    videoNode.removeEventListener("waiting", this.handleVideoStateChange)
    videoNode.removeEventListener("playing", this.handleVideoStateChange)
    videoNode.removeEventListener("seeked", this.handleVideoStateChange)
    videoNode.removeEventListener("stalled", this.handleVideoStateChange)
    videoNode.removeEventListener("suspend", this.handleVideoStateChange)
    videoNode.removeEventListener("ended", this.handleVideoStateChange)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // update sources if video/sources props changed
    const { sources: prevSources, video: prevVideo } = prevProps
    const { sources, video } = this.props

    if (sources !== prevSources || video !== prevVideo) {
      this.getSources()
      this.adaptSourcesToScreen()
    }

    // load video if sources changed
    const { sources: prevStateSources } = prevState
    const { sources: stateSources } = this.state

    const videoNode = this.videoRef && this.videoRef.current
    if (!(videoNode instanceof HTMLVideoElement)) return

    if (stateSources !== prevStateSources) {
      videoNode.load()
      this.handleVideoStateChange()
    }
  }

  render() {
    let {
      // element data
      data,
      // element props
      sources,
      video,
      autoPlay,
      loop,
      muted,
      controls,
      playButton,
      pauseButton,
      muteButton,
      unmuteButton,
      thumbnail,
      gsapAnimations,
      // class names
      videoClassName,
      videoWrapperClassName,
      controlsClassName,
      playButtonClassName,
      pauseButtonClassName,
      muteButtonClassName,
      unmuteButtonClassName,
      className,
      // passthru
      ...videoProps
    } = this.props

    return (
      <div
        ref={c => (this.self = c)}
        {...classNames("video")
          .plus(className)
          .plus(videoClassName)}
      >
        {thumbnail}
        <div {...classNames("video_wrapper").plus(videoWrapperClassName)}>
          <video
            ref={this.videoRef}
            controls={false}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted || autoPlay}
            //$FlowFixMe
            {...videoProps}
          >
            {this.state.sources.map(({ src, type }) => (
              <source key={src} src={src} type={type} />
            ))}
          </video>
        </div>

        {controls && (
          <div {...classNames("controls").plus(controlsClassName)}>
            <button
              {...classNames("play_button").plus(playButtonClassName)}
              onClick={this.play}
            >
              {playButton}
            </button>
            <button
              {...classNames("pause_button").plus(pauseButtonClassName)}
              onClick={this.pause}
            >
              {pauseButton}
            </button>
            <button
              {...classNames("mute_button").plus(muteButtonClassName)}
              onClick={this.mute}
            >
              {muteButton}
            </button>
            <button
              {...classNames("unmute_button").plus(unmuteButtonClassName)}
              onClick={this.unmute}
            >
              {unmuteButton}
            </button>
          </div>
        )}
      </div>
    )
  }
}
/**
 * Exports
 */
export default Video
